.container{
    display: flex;
    align-items: center;
    cursor: pointer;
}
.labelItem{
    color: #1E202C;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    margin-right: 4px;
}

@media (min-width: 774px){
    .labelItem{
        font-size: 16px;
    }
}