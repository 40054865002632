.container {
    width: 100%;
    padding: 4% 0;
    /* background-color: #F7EFED; */
    background-color: #686868;
}

.containerGrid {
    display: grid;
    grid-template-columns: 0.5fr repeat(4, 1fr) 0.5fr;
}

.containerGrid section {
    display: flex;
    flex-flow: column;
}

.titleFooter {
    font-family: "Raleway";
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    /* color: #5F4840; */
    color: #fff;
}

.footerContent {
    font-family: "Raleway";
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    /* color:#87655B; */
    color: #fff;
    margin-top: 8.435%;
    cursor: pointer;
    text-decoration: none;
}

.containerSorella{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 4%;
}

.sorella {
    color: #fff;
    font-family: Raleway;
    font-size: 40px;
    font-weight: 800;
    letter-spacing: 12px;
}