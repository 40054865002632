.titleFilters {
    display: flex;
    justify-content: center;
    margin-top:80px;
    font-family:"Raleway";
    font-size: 23px;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
    color:#87655B;
}
.scrollContaint{
    max-height: 100%;
    width: 100%;
    overflow-y: auto;
    background-color: #FAFAFA;
}