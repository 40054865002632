.containerTerms {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 24px;
    width: 664px;
}

.titleTermsContainer {
    margin: 24px 81px 28px 16px;
}

.colorSelected{
    color: #74807E !important;
}

.titleTerms {
    font-family: "Raleway";
    font-size: 24px;
    font-weight: 400;
    letter-spacing: 0em;
    text-align: left;
    color: #5F4840;
    left: 24px;
    height: 40px;
    width: 288px;
}

.updateFileButtonArea{
    display: flex;
    justify-content: center;
}

.updateFileButton {
    border: solid 1px #87655B;
    cursor: pointer;
    background: none;
    width: 95%;
    height: 40px;
    font-family: "Mulish";
    font-size: 13px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: center;
    color: #87655B;
    padding: 12px 20px 12px 20px;
}
.uploadFileButton{
    border: solid 1px #87655B;
    cursor: pointer;
    background: none;
    width: 95%;
    font-family: "Mulish";
    font-size: 13px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: center;
    background-color: #87655B;
    color:#FFFF;
    transition-duration: 0.4s;
    padding: 11px 0px;
}
.uploadFileButton::before{
    content: url('../../assets/icons/updateArrowUp.svg');
    margin-right: 9px;
}
.selected{
    border: 1px solid #74807E;
}

.updateArrow{
    margin-right: 9px;
}

.update {
    font-family: "Mulish";
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    font-weight: 600;
    color: #1E202C;
}

.conditions {
    font-family: "Mulish";
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #1E202C;
}

.textTerms {
    font-family: "Mulish";
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: justify;
    font-weight: 400;
}

.checkTerms {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.textCheckConditions {
    font-family: "Mulish";
    margin-left: 12px;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    color: #353535;
}

.buttonsTermsArea {
    display: flex;
    flex-direction: row;
    gap: 16px;
}

.buttonCancel {
    display: flex;
    justify-content: center;
    height: 40px;
    border: 1px solid #E4E4E4;
    cursor: pointer;
    background: none;
    font-family: "Mulish";
    font-size: 13px;
    font-weight: 600;
    text-align: left;
    color: #353535;
    padding: 12px 20px;
}

.buttonContinue {
    display: flex;
    justify-content: center;
    height: 40px;
    border: 1px solid #EFDED9;
    cursor: pointer;
    background: #EFDED9;
    font-family: "Mulish";
    font-size: 13px;
    font-weight: 600;
    text-align: left;
    color: #A57C70;
    padding: 12px 20px;
}

.rentBookingButtonsArea {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.rentBookingButton {
    width: 144px;
    height: 40px;
    border: none;
    cursor: pointer;
    background: none;
    font-family: "Raleway";
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: #74807E;
    display: flex;
    justify-content: center;
    align-items: center;
}