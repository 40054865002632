.buttonSizeContainer{
    margin: 0px 24px;
}
.checkboxInline{
    color: #000;
}
.buttonSize {
    padding: 0px 3%;
    border: none;
    background-color: transparent;
    color: #454A5F;
    cursor: pointer;
    transition-duration: 0.4s;
    height: 38px;
    width: fit-content;
    font-family: 'Mulish';
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
}
.containCheckbox{
    min-width: 50%;
    margin-bottom: 18px;
}
.buttonSize:hover:disabled {
    background-color: #FFFF;
    color: #454A5F;
}