.container{
    display: flex;
    flex-direction: column;
    padding: 16px 36px;
}
.textMexico{
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 14px;
    color: #000;
}
.inline{
    display: flex;
    align-items: center;
}
.containerCart {
    display: flex;
    flex-direction: row;
    row-gap: 10px;
    margin-top: 16px;
    margin-bottom: 32px;
    flex-wrap: wrap;
}
.containerCard{
    display: flex;
    justify-content: space-between;
}
.containerCardProduct{
    display: flex;
}
.containImg{
    width: 88px;
    margin-right: 12px;
}
.imgStyle{
    width: 100%;
    object-fit: cover;
}
.containTexs{
    max-width: 160px;
}
.titleCard{
    font-size: 14px !important;
    color: #353535 !important;
}
.textEdit{
    font-size: 14px;
    font-family: 'Mulish';
    font-weight: 400;
    color: #BF9081;
    text-decoration: underline;
    margin-left: 5px;
}
.rentNowButton{
    border: none;
    cursor: pointer;
    height: 52px !important;
    width: 100%;
    background-color: #D6A191;
    margin-top: 20px;
}

.textButton{
    font-family: "Mulish" !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 20px !important;
    letter-spacing: 0em !important;
    color:#FFFF;
}

.bookingButton{
    cursor: pointer;
    height: 52px;
    width: 100%;
    border-radius: 0px;
    padding: 16px 24px 16px 24px;
    border: solid 1px #D6A191;
    font-family: "Mulish";
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    color:#D6A191;
    background-color: #F7EFED;
    margin-top: 20px;
}
.inlineContainer{
    margin-top: 36px;
    margin-bottom: 28px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}
.textNormal{
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #D1D1D1;
    padding: 10px 10px;
    margin: 0px 12px;
}
.active{
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    color: #74807E;
}
.decorationLine{
    margin-top: 13px;
    border-bottom: 1.5px solid #74807E;
}
.textEmpty{
    text-align: center;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #879491;
}
.numerationDecorative{
    display: flex;
    font-family: 'Raleway';
    align-items: center;
    justify-content: center;
    font-size: 10px;
    width: 14px;
    height: 14px;
    border-radius: 50px;
    background: #5F6867;
    color: #FFF;
}
.contentSelectSize{
    margin-top: 20px;
}

@media (min-width: 774px) {
    .widthDektop{
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 50%;
        text-align: center;
    }
    .textNormal{
        width: 100%;
        text-align: center;
        cursor: pointer;
    }
    .containerCard{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
    .imgStyle{
        height: 180px;
        width: 100px;
        object-fit: cover;
    }
    .containDesktop{
        width: 40%;
        max-width: 300px;
    }
    .containImg{
        width: 100%;
        height: 100%;
        max-height: 100%;
    }
    .containTexs{
        width: 100%;
        max-width: 150px;
        min-width: 150px;
    }
    .linesDecorationDesktop{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        border-bottom: 1.5px solid #74807E;
        border-top: 1.5px solid #74807E;
        min-height: 100px;
    }
}