.container {
    display: grid;
    grid-template-areas: "nav  nav"
        "sec1 sec2";
    grid-template-rows: 72px 1fr;
    grid-template-columns: 72% 28%;
}

.nav {
    grid-area: nav;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 10;
    box-shadow: 0px 0px 6px #e0e0e0;
}

.inlineSections{
    display: flex;
    justify-content: space-between;
}

.sectionMobileColumn{
    display: flex;
    flex-direction: column;
}

.section1 {
    grid-area: sec1;
    background-color: #FFFF;
    height: 100%;
}

.dressSection {
    min-height: 100vh;
    margin-bottom: 70px;
    background-color: white;
}

.dressContainer {
    display: flex;
    justify-content: center;
    gap: 30px;
    height: 100%;
}

.imagesContainer{
    display: grid;
    background-color: #FFFF;
    grid-template-columns: 0.3fr 0.7fr;
    padding: 150px 128px 150px 148px;
    column-gap: 64px;
}
.mainDressImage{
    display: flex;
    justify-content: center;
}
.mainImage{
    width: 100%;
    max-height: 785px;
    object-fit: contain;
}
.additionalDressImages{
    display: flex;
    flex-flow: column;
    align-items: center;
    row-gap: 32px;
}

.additionalImages{
    width: 181px;
    height: 268px;
}

.section2 {
    grid-area: sec2;
    overflow-y: auto;
    background-color: transparent;
}

.section2::-webkit-scrollbar{
    display: none;
}

.rentButtonsArea{
    display: flex;
    flex-direction: column;
    margin: 32px;
    gap: 32px;
    align-items: center;
}

.rentNowButton{
    border: none;
    cursor: pointer;
    height: 52px;
    width: 337px;
    padding: 16px 24px 16px 24px;
    background-color: #D6A191;
    color:#FFFF;
    font-family: "Mulish";
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
}

.bookingButton{
    cursor: pointer;
    height: 52px;
    width: 337px;
    border-radius: 0px;
    padding: 16px 24px 16px 24px;
    border: solid 1px #D6A191;
    font-family: "Mulish";
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    color:#D6A191;
    background-color: #F7EFED;
}

.containImgMainMobile{
    background-color: #FFF;
    height: 448px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.additionalImagesMobile{
    width: 256px;
    height: auto;
}

.textMexico{
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 19px;
    line-height: 22px;
    color: #000000;
}

.containerInline{
    display: flex;
    align-items: center;
}

.containerFilterModule{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px 12px 32px;
    gap: 16px;
    background: #FAF6F5;
    border-width: 1px 0px;
    border-style: solid;
    border-color: #D1D1D1;

}
.rotateCenter{
	-webkit-animation: rotate-center 6s linear infinite both;
	        animation: rotate-center 6s linear infinite both;
}
@-webkit-keyframes rotate-center {
    0% {
      -webkit-transform: rotate(0);
              transform: rotate(0);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
}
@keyframes rotate-center {
    0% {
      -webkit-transform: rotate(0);
              transform: rotate(0);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
}
