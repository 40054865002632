.container {
    display: grid;
    grid-template-areas: "nav nav"
                         "la  ra";
    height: 100%;
    background-color: #F7EFED;
    grid-template-columns: auto 1fr;
    grid-template-rows: 72px 1fr;
}

.nav{
    grid-area: nav;
}

.leftArea {
    grid-area: la;
    height: 100%;
    position: relative;
}

.rightArea {
    grid-area: ra;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}


.imageLogin {
    position: relative;
    width: 100%;
    height: 100%;
    background-image: url('../../assets/svg/imageLogin.svg');

    /* background-size: contain; */
    background-repeat: no-repeat;

    background-size: cover;

    display: flex;
    justify-content: center;
    align-items: center;
}

.imgCircleSorella {
    width: 35.649%;
    height: 35.649%;
    z-index: 2;
    position: absolute;
}

.imgCircleMobile{
    width: 60px;
    height: 60px;
}

.containerImgCircleMobile{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90px;
}


.containerMobileImgMain{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
}
    

.containerLeftImg{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: fit-content;
}

@media (max-height: 750px) {
    .cardContainer {
        background-color: white;
        width: 60.766%;
        padding: 0% 3%;
    }
}

