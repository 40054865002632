.d-flex {
    display: flex;
}

.flex-row {
    flex-direction: row;
}

.flex-col {
    flex-direction: column;
}

.justify-content-center {
    justify-content: center;
} 

.align-center {
    align-items: center;
}

.mt-1 {
    margin-top: 1rem;
}

.rdt_TableHeadRow, .rdt_TableHeadRow, .rdt_TableCol {
    background-color: #FAF6F5;
    box-shadow: 0px -1px 0px 0px #EDF2F7 inset;
    height: 52px;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    color: #353535;
    text-transform: uppercase;
}

.rdt_TableCell {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;
    color: #353535;
    box-shadow: 0px -1px 0px 0px #EDF2F7 inset;
}

.image-column {
    padding-top: 5px;
}

.pointer {
    cursor: pointer;
}

.DraftEditor-root {
    background-color: white;
    padding: 12px;
    font-size: 12px;
    line-height: 6px;
    min-height: 100px;
    box-shadow: 0px 1px 2px rgb(50 50 71 / 8%), 0px 0px 1px rgb(50 50 71 / 20%);
}

.form-order::-webkit-scrollbar {
    width: 5px;
    border-radius: 13px;
    background-clip: padding-box;
    background-color: #aaa;
}
.form-order::-webkit-scrollbar-track {
    border-radius: 13px;
    background: #aaa;
}
.form-order::-webkit-scrollbar-thumb {        
    box-shadow: inset 0 0 0 10px;
    border-radius: 13px;
    background: #aaa;
}