.priceArea {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;
    margin: 0 20px 64px 26px;
    align-items: flex-end;
}

.inputSections {
    display: flex;
    flex-direction: column;
    gap: 7px;
}

.priceInput{
    border: none;
    width: 75%;
    height: 36px;
    margin-top: 8px;
}

.priceSpan {
    font-family: "Raleway";
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color:#686868;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.mt1 {
    margin-top: 1rem;
}