.container{
    display: flex;
    flex-direction: column;
    padding: 16px 22px;
}
.containerCart{
    margin: 24px 0px;
}
.inlineContainer{
    display: flex;
    align-items: center;
    justify-content: flex-start;
}