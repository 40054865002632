.measurementArea {
    display: flex;
    flex-direction: row;
    padding:0px 26px;
}

.sizeContainer{
    display: flex;
    flex-direction: column;
}

.sizeSections {
    display: flex;
    flex-direction: column;
    width: 150px;
    gap: 16px;
}

.sizeSpanTitle{
    font-family: "Mulish";
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color:#1E202C;
}

.sizeSpan {
    font-family: "Mulish";
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #1E202C;
}

.sorellaTable{
    background-color: #FFFFFF;
    width: 100%;
    text-align: left;
    border-collapse: collapse;
    font-family: 'Mulish';
}
.sorellaTable td, .sorellaTable th {
    border: 1px solid #EDF2F7;
    padding: 3px 2px;
  }
  .sorellaTable tbody td {
    font-size: 13px;
    color: #353535;
  }
  .sorellaTable td:nth-child(even) {
    background: #FAFAFA;
  }
  .sorellaTable thead {
    background: #EDEFEF;
    background: -moz-linear-gradient(top, #f1f3f3 0%, #eef0f0 66%, #EDEFEF 100%);
    background: -webkit-linear-gradient(top, #f1f3f3 0%, #eef0f0 66%, #EDEFEF 100%);
    background: linear-gradient(to bottom, #f1f3f3 0%, #eef0f0 66%, #EDEFEF 100%);
  }
  .sorellaTable thead th {
    font-size: 15px;
    font-weight: bold;
    color: #353535;
  }
  .sorellaTable tfoot td {
    font-size: 14px;
  }
  .sorellaTable tfoot .links {
    text-align: right;
  }
  .sorellaTable tfoot .links a{
    display: inline-block;
    background: #1C6EA4;
    color: #FFFFFF;
    padding: 2px 8px;
    border-radius: 5px;
  }