.containVertical{
    display: block;
    flex-direction: column;
    align-items: center;
    margin-left: 20px;
}

.pointIndicator{
    width: 12px;
    height: 12px;
    border-radius: 12px;
    border: 1px solid #D6A191;
    background-color: #FFF;
    margin-top: 18px;
}

.active{
    background-color: #D6A191;
}