.buttonFaqColors {
    border: none;
    cursor: pointer;
    background: none;
    height: 46px;
}

button {
    padding: 0px;
}

.colorContainer{
    display: flex;
    flex-flow: wrap;
    column-gap: 24px;
    row-gap: 24px;
    padding: 0px 26px;
}