.container {
    display: grid;
    grid-template-areas: "nav  nav"
        "sec1 sec2";
    background-color: #FAF6F5;
    grid-template-rows: 72px 1fr;
    grid-template-columns: 0.3fr 0.7fr;
}

.nav {
    grid-area: nav;
}

.section1 {
    grid-area: sec1;
    background-color: #F7EFED;
    height: 100%;
    margin-bottom: 200px;
}

.section2 {
    grid-area: sec2;
    background-color: #FFFF;
    padding-bottom: 191px;
}

.topSection {
    display: flex;
    justify-content: space-around;
    margin-top: 56px;
}

.modelsForEachPage {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;
    margin-bottom: 32px;
}

.qtyModels {
    height: 40px;
    width: 84px;
    margin: 1px;
    padding: 10px;
    border: none;
    padding: 10px;
    box-shadow: 0px 2px 4px 0px #0510370F;
}

.topLeftArea {
    
}

.topRightArea {
    
}

.filtersArea {
    display: flex;
    column-gap: 24px;
    margin-top: 16px;
    margin-bottom: 32px;
}

.titleStyles {
    font-family: "Raleway";
    font-size: 48px;
    font-weight: 400;
    line-height: 56px;
    letter-spacing: 0em;
    text-align: left;
    color: #5F4840;
    margin-right: 100px;
}

.filterItem {
    height: 22px;
    width: fit-content;
    padding: 5px;
    left: 0px;
    top: 0px;
    border-radius: 0px;
    padding: 2px, 8px, 2px, 8px;
    background-color: #D6A191;
    color: #FFFF;
    font-family: Mulish;
    font-size: 11px;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: center;
    display: flex;
    align-items: center;
}

.close {
    border: none;
    cursor: pointer;
    background: none;
}

.titlePaginationModels {
    font-family: "Raleway";
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: right;
    color:#5F4840;
    margin-right: 8px;
}

.paginationTop{
    display: flex;
    align-items: center;
}

ul.pagination {
    display: inline-block;
    padding: 0;
    margin: 0;
    height: 40px;
    box-shadow: 0px 2px 4px 0px #0510370F;
}

ul.pagination li {
    display: inline;
}

ul.pagination li a {
    color: black;
    float: left;
    padding: 0px 16px;
    text-decoration: none;
}

.allDressSearchContainer {
    display: flex;
    column-gap: 48px;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 10px;
}

.paginationWrapper{
    display: flex;
    justify-content: flex-end;
    margin: 0 16px;
    padding: 16px 0;
    align-items: center;
    gap: 8px;
}
.spanPage{
    font-family: Raleway;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: right;
    color:#5F4840;
}
.paginationContainer{
    display: flex;
    align-items: center;
    padding: 8px 0;
    background-color: white;
    box-shadow: 0px 2px 4px rgba(5, 16, 55, 0.06);
}
.arrows{
    width: 9px;
    height: 9px;
    padding: 7.5px 9.5px;
}
.pages{
    display: flex;
    align-items: center;
    font-family: Mulish;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
}
