.infoProfileArea {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}
.containImgBanner{
    width: 100%;
    max-height: 138px;
    object-fit: cover;
}

.userNameArea {
    font-family: "Raleway";
    font-size: 17px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    margin: 0px 8.5px 0px 8.5px
}

.userLocationArea {
    font-family: "Mulish";
    font-size: 14px;
    Font-style: Medium;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    margin: 8px 8.5px 16px 8.5px;
    color: #425466;
}

.profileButtonsArea {
    display: flex;
    justify-content: center;
    flex-direction: row;
}

.profileImage {
    width: 64px;
    height: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #97A6A3;
    cursor: pointer;
    border-radius: 50px;
    position: relative;
    top: -26px
}

.ordersButton {
    width: 144px;
    height: 40px;
    border: none;
    cursor: pointer;
    background: none;
    font-family: "Raleway";
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: #74807E;
    display: flex;
    justify-content: center;
    align-items: center;
}

.selected {
    border: 1px solid #74807E;
}

.profile{
    display: flex;
    flex-direction: column;
}

.areaEditButton{
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.buttonPaddingLeft{
    margin-left: 12px;
}