.subTitleContainer {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
}

.collapsableHeader {
    display: flex;
    justify-content: center;
    margin-bottom: 48px;
}

.subTitle {
    font-family: 'Poppins';
    font-size: 22px;
    font-weight: 400;
    line-height: 33px;
    color: #353535;
    letter-spacing: 0em;
    text-align: left;
    display: block;

}

.showHideButton {
    border: none;
    cursor: pointer;
    background: none;
}

.buttonSizeContainer {
    display: grid;
    justify-content: center;
    grid-template-columns: 80px 80px;
    column-gap: 24px;
    row-gap: 32px;
}

.buttonSize {
    border: none;
    background-color: #FFFF;
    color: #454A5F;
    cursor: pointer;
    transition-duration: 0.4s;
    height: 53px;
    width: 68px;
    left: 0px;
    top: 0px;
    border-radius: 0px;
    font-family: "Mulish";
    font-size: 23px;
    font-weight: 500;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: center;
    padding: 12px;
}
.buttonSize:hover:disabled {
    background-color: #FFFF;
    color: #454A5F;
}
.buttonSize:disabled {
    cursor: auto;
    opacity: 0.4;
}

.buttonSize:hover {
    background-color: #C7CECC;
    color: #434A48;
}

.selected{
    background-color: #C7CECC;
    color: #434A48;
}

.searchButton {
    border: solid 1px;
    border-radius: 8px;
    border-color: #D6A191;
    background-color: #D6A191;
    color: #FFFF;
    padding: 4.575% 6.86%;
    margin-top: 5%;
    cursor: pointer;
}

.startSearchContainer {
    display: flex;
    justify-content: center;
    margin-top: 40px;
}

.startSearch {
    border: solid 1px;
    border-radius: 8px;
    border-color: #EFDED9;
    background-color: #EFDED9;
    color: #A57C70;
    cursor: pointer;
    width: 336px;
    height: 52px;
    font-family: Mulish;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    display: flex;
    justify-content: center;
    align-items: center;
}

.justExploring {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 14px;
    margin-top: 24px;
    display: flex;
    justify-content: center;
    text-align: center;
    color: #5F6867;
    text-decoration: none;
}