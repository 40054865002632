.pageNumbers {
    list-style: none;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    height: 40px;
  }

@media (max-width: 744px) {
  .pageNumbers {
    gap: 4px !important;
    height: 60px !important;
  }
  .pageNumbers li {
    font-size: 14px !important;
    padding: 6px 14.5px !important;
    gap: 5px !important;
    height: 25px !important;
  }
}
  
  .pageNumbers li {
    display: flex;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 19.5px;
    gap: 10px;
    height: 40px;
    background: #FFFFFF;
    cursor: pointer;
    box-shadow: 0px 2px 4px 0px #0000000F;
  }
  
  .pageNumbers li.active {
    background-color: #C7CECC;
    color: #434A48;
  }
  
  .pageNumbers li button {
    border: none;
    background-color: transparent;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #000000;
    cursor: pointer;
  }
  .pageNumbers li button:hover {
    background-color: white;
    color: black;
  }
  .pageNumbers li button:focus {
    outline: none;
  }
  
  .loadmore {
    padding: 1rem;
    background-color: transparent;
  
    color: #000;
    font-size: 1.2rem;
    border: 1px solid white;
    cursor: pointer;
  }