.containerHashtags{
    position: absolute;
    bottom: 70px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: flex-start;
    width: 100%;
    min-height: 26px;
}
.containerHashtagsDetails{
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 12px;
}
.containTextsDetails{
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #000000;
    margin-left: 16px;
    margin-right: 16px;
    padding: 6px 8px;
}
.containTexts{
    padding: 6px 8px;
    display: flex;
    align-items: center;
    margin-left: 16px;
    margin-right: 16px;
    border-radius: 24px;
}
.containTexts p{
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    line-height: 16px;
    display: flex;
    align-items: center;
    margin: 0px;
    color: #000;
}
.containTexts img{
    height: 11px;
    width: 11px;
    margin-right: 10px;
}
.containTextsDetails img{
    height: 16px;
    width: 16px;
    margin-right: 10px;
}