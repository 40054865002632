.dressImagesContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px;
    overflow-y: hidden;
    height: 80vh;
    max-width: 155px;
}

.dressImagesSection {
    top: 21px;
    overflow-y: hidden;
    margin: 16px 43px 0px 43px;
    position: relative;
}

.dressImagesUpArrow {
    position: absolute;
    top: 0;
    left: 45%;
}

.dressImagesDownArrow {
    position: absolute;
    bottom: -10px;
    left: 45%;
}

.dressImageContent {
    height: 20vh;
    width: calc(20vh * 9 / 16);
    position: relative;
    border-radius: 12px;
    overflow: hidden;
}

.dressImage {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
    cursor: pointer;
    /* border-radius: 12px; */
}

.videoIcon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
}