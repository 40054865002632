.containerMeasuresImg {
    margin: 0px auto;
    padding: 20px;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    gap: 50px;
}

.flexCenter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 64px;
    height: 70%;
}

.breadCrumbContainer{
    display: flex;
    padding-left: 30.32px;
}
.breadCrumbText{
    font-family: 'Mulish';
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
}
.imgBreadCrumb{
    padding: 0 13.75px;
}

.titleGuide {
    display: flex;
    font-family: "Raleway";
    font-size: 17px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #5F4840;
    left: 24px;
    height: 40px;
    width: 288px;
    letter-spacing: 0em;
    margin-top: 24px;
    margin-left: 30.32px;
}

.backToProfileArea {
    padding: 20px;
    display: flex;
    justify-content: center;
}

.backButton {
    padding: 12px 20px 12px 20px;
    max-width: 400px;
}

.backToProfileButton {
    height: 40px;
    width: 288px;
    left: 0px;
    top: 0px;
    border-radius: 0px;
    padding: 12px 20px 12px 20px;
    border: 1px solid #87655B;
    font-family: "Mulish";
    font-size: 13px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: center;
    color: #87655B;
    cursor: pointer;
    background: none;
    margin-bottom: 35.61px;
}

.tableContainer {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.tableContainer p {
    font-family: 'Mulish';
    color: #1E202C;
    font-size: 12px;
}

.Sorella {
    font-family: 'Raleway';
    background-color: #FFFFFF;
    width: 100%;
    text-align: center;
    border-collapse: collapse;
}

.Sorella td, .Sorella th {
    border: 1px solid #EDF2F7;
    padding: 24px 10px;
}

.Sorella tbody td {
    font-size: 13px;
    color: #353535;
}

.Sorella td:nth-child(even) {
    background: #FAFAFA;
}

.Sorella thead {
    background: #EDEFEF;
    background: -moz-linear-gradient(top, #f1f3f3 0%, #eef0f0 66%, #EDEFEF 100%);
    background: -webkit-linear-gradient(top, #f1f3f3 0%, #eef0f0 66%, #EDEFEF 100%);
    background: linear-gradient(to bottom, #f1f3f3 0%, #eef0f0 66%, #EDEFEF 100%);
}

.Sorella thead th {
    font-size: 15px;
    font-weight: bold;
    color: #353535;
}

.Sorella tfoot td {
    font-size: 14px;
}

.Sorella tfoot .links {
    text-align: right;
}

.Sorella tfoot .links a {
    display: inline-block;
    background: #1C6EA4;
    color: #FFFFFF;
    padding: 2px 8px;
    border-radius: 5px;
}

@media (min-width: 841px) {

    .flexCenter {
        max-height: 600px;
    }

    .containerMeasuresImg {
        height: 75vh;
    }
}

@media (max-width: 840px) {

    .flexCenter {
        flex-direction: column;
    }

    .Sorella td, .Sorella th {
        border: 1px solid #EDF2F7;
        padding: 24px 5px;
    }

    .containerMeasuresImg {
        padding: 20px 20px 40px 20px;
    }

    .backToProfileArea {
        padding: 0px;
    }
}