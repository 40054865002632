.container {
    position: relative;
    max-width: 100%;
}
.containerInline{
    display: flex;
    align-items: center;
    overflow-x: auto;
}
.containerInline::-webkit-scrollbar {display: none;}
.cards{
    position: relative;
    height: 617px;
    min-width: 347px;
    max-width: 347px;
    border-radius: 24px;
    margin: 34px 24px 34px 34px;
    cursor: pointer;
}
.cards img {
    filter: blur(.15px);
}
.arrowRight{
    background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}
.cards h2,
.squareCard h2,
.rectangleCard h2 {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    text-transform: uppercase;
    color: #FFFFFF;
    text-shadow: 3px 3px 3px rgba(0, 0, 0, 0.3);
}
.cards p,
.squareCard p,
.rectangleCard p {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
    text-decoration-line: underline;
    text-transform: uppercase;
    color: #FFFFFF;
    cursor: pointer;
}
.titleHome{
    position: absolute;
    display: flex;
    left: 0px;
    right: 34px;
    justify-content: flex-end;
    align-items: center;
    bottom: 23%;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 53px;
    line-height: 80px;
    text-align: right;
    color: #FAF6F5;
}
.titleSection {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    color: #353535;
    padding: 0px 32px;
}
.sectionSalesContainer {
    position: relative;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    max-height: 670px;
    margin-bottom: 32px;
    gap: 12px;
}
.row {
    position: relative;
    display: flex;
    flex-direction: row;
    column-gap: 24px;
    height: 600px;
    padding: 0px 32px;
}
.column {
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    flex: 0 1 50%;
}
.squareCard {
    position: relative;
    border-radius: 24px;
    flex: 0 1 50%;
}
.rectangleCard {
    position: relative;
    height: 48%;
    border-radius: 24px;
}
@media (max-width: 900px) {
    .sectionSalesContainer .row {
        overflow-x: scroll;
        overflow-y: hidden;
    }
    .sectionSalesContainer .row::-webkit-scrollbar {
        display: none;
    }
    .sectionSalesContainer .column {
        flex-direction: row;
        column-gap: 24px;
    }
    .sectionSalesContainer .squareCard,
    .sectionSalesContainer .rectangleCard {
        height: 100%;
        min-width: 45vw;
        max-width: 45vw;
    }
}

@media (max-width: 430px) {
    .sectionSalesContainer {
        max-height: 700px;
    }
    .titleSection {
        font-size: 28px;
    }
    .sectionSalesContainer .squareCard,
    .sectionSalesContainer .rectangleCard {
        height: 100%;
        min-width: 83vw;
        max-width: 83vw;
    }
}

@media (max-width: 774px) {
    .cards{
        height: 511px !important;
        min-width: 280px !important;
        max-width: 280px !important;
        border-radius: 19px !important;
        margin: 20px 24px 20px 20px;
    }
    .cards h2{
        font-size: 26.5322px !important;
        line-height: 33px !important;
    }
    .cards p{
        font-size: 16px !important;
        line-height: 19px;
    }
    .titleHome{
        position: absolute;
        display: flex;
        left: 0px;
        right: 0px;
        justify-content: center;
        align-items: center;
        bottom: 23%;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 31px;
        line-height: 46px;
        text-align: center;
        color: #FAF6F5;
    }
}
@media (max-width: 420px) {
    .cards h2 {
        font-size: 24px !important;
    }
}
.nav {
    grid-area: nav;
    width: 100%;
}
.findYourDressWrapper{
    margin-bottom: 4%;
    width: 336px;
    background-color: #87655B !important;
    text-transform: uppercase;
    color: #FFFFFF !important;    
}
.section1 {
    position: relative;
    overflow: hidden;
    width: 100%;
    cursor: pointer;
}

.section2 {
    background-color: white;
    padding: 5.556% 0;
    width: 100%;
}

.figGirlLayDown {
    width: 100%;
    height: 100%;
}

.imgCircleSorella {
    position: absolute;
    width: auto;
    height: 18.6%;
    top: 5%;
    left: 6.747%;
}

.frameArea {
    position: absolute;
    height: auto;
    bottom: 20%;
    right: 3%;
    width: 43%;
}

.faqsContainer {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: space-evenly;
}

.containerRentNow {
    width: 98%;
    bottom: 13%;
    position: absolute;
    display: flex;
    align-items: center;
}

.rentNowButton {
    border: solid 1px;
    border-color: #fff;
    background-color: #fff;
    color: #000;
    cursor: pointer;
    width: 336px;
    height: 52px;
    font-family: "Mulish";
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0em;
    display: flex;
    justify-content: center;
    align-items: center;
}

.startSearchContainer{
    display: flex;
    justify-content: center;
    margin-top: 40px;
}
.startSearch {
    border: solid 1px;
    border-color: #000;
    background-color: #000;
    color: #fff;
    cursor: pointer;
    width: 336px;
    height: 52px;
    font-family: Mulish;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
}
.startSearch:disabled {
    border: solid 1px;
    text-transform: uppercase;
    border-color: #F2F2F2;
    background-color: #F2F2F2;
    color: #000;
    cursor: default;
    width: 336px;
    height: 52px;
    font-family: Mulish;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    display: flex;
    justify-content: center;
    align-items: center;
}
.justExploring {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 12.5889px;
    line-height: 14px;
    display: flex;
    text-decoration: underline;
    justify-content: center;
    color: #949494 !important;
}
.line {
    margin-right: 1%;
}
.howDoesItWork {
    font-family: 'Poppins';
    font-size: 32px;
    font-weight: 400;
    line-height: 59px;
    letter-spacing: 0em;
    text-align: center;
    color: #000;
    margin-top: 56px;

}
.allSteps{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    height: auto;
    margin: 7.12% 0;
    column-gap: 3%;
}
.step{
    display: flex;
    flex-basis: 140px;
    font-family: "Raleway";
    font-weight: 600;
    font-size: 17px;
    margin-bottom: 40px;
}
.containerStep{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.containerStep span:first-child{
    font-family: "Raleway";
    font-weight: 600;
    font-size: 17px;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
    color:#686868;
    margin-bottom: 24px;
}
.containerStep span:nth-child(2){
    color:#000;
    text-align: center;
    font-size: 17px;
}
.lineRight{
    /* margin-top: 5%; */
    background-color: #D1D1D1;
    height: inherit;
    width: 1px;
}

.sectionHowItWorks{
    background-color: #fff !important;
   width: 100%;
   display: flex;
   flex-flow: column;
   align-items: center;
}
@media (max-height: 1239px) and (min-width:770px) {
}
.containerImgBannerMainMobile{
    position: relative;
    height: 576px;
    width: 100%;
    cursor: pointer;
}
.figGirlLayDownMobile{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.lineDecorationMobile{
    display: flex;
    height: 1px;
    width: 168px;
    background-color: #FFF;
}
.pointDecorationMobile{
    height: 7px;
    width: 7px;
    background-color: #FFF;
    border-radius: 5px;
    float: right;
    margin-top: -4px;
}
.rentNowButtonMobile {
    border: solid 1px;
    border-color: #FFF;
    background-color: #FFF;
    color: #000;
    cursor: pointer;
    width: 186px;
    height: 40px;
    font-family: "Mulish";
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0em;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 15px;
    text-transform: uppercase;
}
.containerRentNowMobile{
    position: absolute;
    bottom: 70px;
    right: 20px;
    display: flex;
    align-items: center;
}
.sectionFiltersMobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 48px 0px 32px;
    gap: 20px;
    background-color: #FFF;
}

.wrapperImage{
    width: 100%;
    display: flex;
    animation: slide 16s ease infinite;
}
.wrapperImage img{
 	min-width: 100%;
 	min-height: 100vh;
    object-fit: cover;
}
@keyframes slide{
    0%,
    30%{
        transform: translateX(0);
    }
    33%,
    63%{
        transform: translateX(-100%);
    }
    66%,
    96%{
        transform: translateX(-200%);
    }
    100%{
        transform: translateX(-300%);
    }
}