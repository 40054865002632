.navContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    width: 100vw;
    left: 0px;
    top: 0px;
    border-radius: 0px;
    padding: 11px 0px 11px 0px ;
    background-color: #fff;
}

.menu {
    font-family: Raleway;
    font-size: 19px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #000;
    padding: 10px;
    border: none;
    cursor: pointer;
    background: none;
    padding: 16px;
}
.rent {
    font-family: Raleway;
    font-size: 19px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #000;
    padding: 10px;
    border: none;
    cursor: pointer;
    background: none;
    padding: 24px;
}

.menuSorellaArea {
    display: flex;
    flex-flow: row;
    align-items: center;
}

.sorella {
    
}

.containerButtonNav {
    display: flex;
    align-items: center;
    column-gap: 4px;
    margin-right: 16px;
}

.buttonNav {
    border: none;
    cursor: pointer;
    background: none;
}

.buttonNavCount {
    font-family: "Raleway";
    font-size: 17px;
    font-weight: 400;
    border: none;
    cursor: pointer;
    background: none;
    padding: 22px 16px 22px 0px;
    color: #000;
}
.buttonNavLogin {
    font-family: "Raleway";
    font-size: 17px;
    font-weight: 400;
    border: none;
    cursor: pointer;
    background: none;
    padding: 22px 16px 22px 0px;
    color: #000;
}
.containerIconNav{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.containIcon{
    position: relative;
    padding: 0px 6px;
}
.containCountCart{
    position: absolute;
    top: -8px;
    right: 0px;
    background-color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 14px;
    width: 14px;
    border-radius: 10px;
}
.numberCountCart{
    color: #FFF;
    font-size: 12px;
    font-family: 'Raleway';
    padding: 0px;
    margin: 0px;
}