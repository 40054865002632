.container {
    display: block;
}

.nav {
    grid-area: nav;
    width: 100%;
}

.containerMobile {
    padding: 24px 16px;
}

.filterSvg {
    padding: 4.88px;
}

.spanFilter {
    font-family: Raleway;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: right;
    color: #5F4840;
}

.btnFilterWrapper {
    display: flex;
    gap: 8px;
    align-items: center;
}

.inlineContainerSpaced {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 400px;
    margin-top: 22px;
    margin-bottom: 24px;
}

.inlineContainer {
    display: flex;
    align-items: center;
    gap: 10px;
    min-width: 400px;
}

.tableResponsive {
    max-width: 100%;
    overflow-x: auto;
    max-height: auto;
    margin-top: 16px;
}

.searchSelect {
    height: 40px;
    width: 180px;
    margin: 1px;
    padding: 10px;
    border: none;
    padding: 10px;
    box-shadow: 0px 2px 4px 0px #0510370F;
}

.searchInput {
    min-width: 400px;
}

.link {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
}

.loaderSpinner{
    border: 8px solid #FFF;
    border-top: 8px solid #C6C8C7;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    animation: spin 1s linear infinite;
}