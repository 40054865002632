.careDescriptionArea {
    display: flex;
    flex-direction: row;
    padding: 0px 26px;
}

.careSpan {
    font-family: "Mulish";
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color:#1E202C
    ;
}