.containerResultsMobile{
    background-color:#FFFF;
}
.containerResultsMobile > section:first-child {
    position: sticky;
    top: 0;
    z-index: 100;
    box-shadow: 0px 0px 6px #e0e0e0;
}
.cardBackGround{
    margin-top: 16px;
    background-color: white;
    border-radius: 0px 0px 6px 6px;
}

.topSection {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-top: 24px;
    margin-left: 16px;
    margin-right: 16px;
}
.btnFilterWrapper{
    display: flex;
    gap: 8px;
    align-items: center;
    
}
.btnFilterAndView{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 5px;
}
.filterSvg{
    padding: 4.88px;
}
.spanFilter {
    font-family: Raleway;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: right;
    color: #5F4840;
}

.allDressSearchContainer {
    display: flex;
    gap: 2.5vw;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 10px;
    min-height: 45.2vh;
}

.toTop {
    position: fixed;
    width: 40px; 
    height: 40px;
    background: #5F4840;
    bottom: 20px; 
    right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 2147483649;
}

@media (min-width: 467px) {
    .allDressSearchContainer {
        padding: 0 24px;
    }
}

@media (max-width: 466px) and (min-width: 340px) {
    .allDressSearchContainer {
        padding: 0 12px;
        justify-content: space-around;
        row-gap: 4vw;
    }
}

.titleAndFilter {
    display: flex;
    flex-direction: column;
}


.titleStyles {
    font-family: "Raleway";
    font-size: 24px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    color: #5F4840;
}
.modelsForEachPage{
    display: flex;
    align-items: center;
    gap: 8px;
}
.titlePaginationModels{    
    font-family: Raleway;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: right;
    color: #5F4840
}
.paginationWrapper{
    display: flex;
    justify-content: flex-end;
    margin: 0 16px;
    padding: 16px 0;
    align-items: center;
    gap: 8px;
}
.spanPage{
    font-family: Raleway;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: right;
    color:#5F4840;
}
.qtyModels{
    box-shadow: 0px 2px 4px rgba(5, 16, 55, 0.06);
    border: none;
}
.paginationContainer{
    display: flex;
    align-items: center;
    padding: 8px 0;
    background-color: white;
    box-shadow: 0px 2px 4px rgba(5, 16, 55, 0.06);
}
.pages{
    display: flex;
    align-items: center;
    
    font-family: Mulish;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;

}
.arrows{
    width: 9px;
    height: 9px;
    padding: 7.5px 9.5px;
}
.filtersArea{
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 90%;
    margin-top: 2px;
    margin-bottom: 2px;
}
.filterItem{
    display: flex;
    align-items: center;
    height: 22px;
    margin-right: 24px;
    margin-bottom: 5px;
    background-color: #C7CECC;
    padding: 0px 8px;
    color: #434A48;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    line-height: 14px;
}
.close{
    border: none;
    background-color: transparent;
    margin: 0px;
    padding: 0px;
    margin-left: 8px;
}
.inlineWrap{
    display: flex;
    flex-wrap: wrap;
    margin-top: 16px;
    margin-bottom: 16px;
}

.rotateCenter{
	-webkit-animation: rotate-center 6s linear infinite both;
	        animation: rotate-center 6s linear infinite both;
}
.itemFadeOut{
    -webkit-animation: fadeout .3s linear forwards;
    animation: fadeout .3s linear forwards;
}
.itemFadeIn {
    -webkit-animation: fadein .3s linear forwards;
    animation: fadein .3s linear forwards;
}

@-webkit-keyframes fadein {
    0% { opacity: 0; }
    100% { opacity: 1; }
}
    
@keyframes fadein {
    0% { opacity: 0; }
    100% { opacity: 1; }
}
    
@-webkit-keyframes fadeout {
    0% { opacity: 1; }
    100% { opacity: 0; }
}
    
@keyframes fadeout {
    0% { opacity: 1; }
    100% { opacity: 0; }
}


@-webkit-keyframes rotate-center {
    0% {
      -webkit-transform: rotate(0);
              transform: rotate(0);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
}
@keyframes rotate-center {
    0% {
      -webkit-transform: rotate(0);
              transform: rotate(0);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
}