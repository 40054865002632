.containerResultsMobile{
    background-color:#FFFF;
}
.cardBackGround{
    margin-top: 16px;
    background-color: white;
    border-radius: 0px 0px 6px 6px;
}

.topSection {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-top: 24px;
    margin-left: 16px;
    margin-right: 16px;
}
.btnFilterWrapper{
    display: flex;
    gap: 8px;
    align-items: center;
    
}
.btnFilterAndView{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.filterSvg{
    padding: 4.88px;
}
.spanFilter {
    font-family: Raleway;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: right;
    color: #5F4840;
}

.allDressSearchContainer {
    display: flex;
    column-gap: 16px;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 10px;
}

.titleAndFilter {
    display: flex;
    flex-direction: column;
}


.titleStyles {
    font-family: "Raleway";
    font-size: 24px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    color: #5F4840;
}
.modelsForEachPage{
    display: flex;
    align-items: center;
    gap: 8px;
}
.titlePaginationModels{    
    font-family: Raleway;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: right;
    color: #5F4840
}
.paginationWrapper{
    display: flex;
    justify-content: flex-end;
    margin: 0 16px;
    padding: 16px 0;
    align-items: center;
    gap: 8px;
}
.spanPage{
    font-family: Raleway;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: right;
    color:#5F4840;
}
.qtyModels{
    box-shadow: 0px 2px 4px rgba(5, 16, 55, 0.06);
    border: none;
}
.paginationContainer{
    display: flex;
    align-items: center;
    padding: 8px 0;
    background-color: white;
    box-shadow: 0px 2px 4px rgba(5, 16, 55, 0.06);
}
.pages{
    display: flex;
    align-items: center;
    
    font-family: Mulish;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;

}
.arrows{
    width: 9px;
    height: 9px;
    padding: 7.5px 9.5px;
}
.filtersArea{
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 90%;
    margin-top: 2px;
    margin-bottom: 2px;
}
.filterItem{
    display: flex;
    align-items: center;
    height: 22px;
    margin-right: 24px;
    margin-bottom: 5px;
    background-color: #C7CECC;
    padding: 0px 8px;
    color: #434A48;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    line-height: 14px;
}
.close{
    border: none;
    background-color: transparent;
    margin: 0px;
    padding: 0px;
    margin-left: 8px;
}
.inlineWrap{
    display: flex;
    flex-wrap: wrap;
    margin-top: 16px;
    margin-bottom: 16px;
}