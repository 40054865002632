.inputsArea {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.inputSections {
    display: flex;
    flex-direction: column;
    margin: 12px 0px 12px 24px;
    max-width: 100%;
}

.textSpan {
    font-family: "Raleway";
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #686868;
}

.itemInputSelect {
    background-color: #fff;
    box-shadow: 0px 0px 1px 0px #32324733;
    border: none;
    height: 36px;
    border-radius: 0px;
}
.valueName {
    font-family: "Mulish";
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    /* color: #686868; */
    color: #425466;
}
.itemInput:disabled, .itemInput1:disabled, .itemInputSelect:disabled, .itemInput2:disabled{
    background-color: #F2F2F2;
}
.itemInput:focus, .itemInput1:focus, .itemInputSelect:focus, .itemInput2:focus{
    border: 1px solid #732130;
}
.itemInput{
    background-color: #FFFFFF;
    border-radius: 0px !important;
}
.numberAndCode{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 12px 0px 0px 0px;
    max-width: 100%;
}
@media (max-width: 1024px){
    .numberAndCode{
        max-width: 100%;
    }
}
.itemInput1{
    background-color: #FFF;
    border-radius: 0px !important;
}
.itemInput2{
    background-color: #FFF;
    box-shadow: 0px 1px 2px 0px #32324714;
    border: none;
    margin-top: 8px;
    height: 36px;
    border-radius: 0px;
}
.reservesDescriptionArea {
    display: flex;
    flex-direction: row;
    margin-left: 22.5px;
}

.profileInformation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: transparent;
    color: #353535;
    cursor: pointer;
    padding: 12px 19px 12px 19px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-family: "Raleway";
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
}

.reservationArea {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.modelSpan {
    font-family: "Raleway";
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: #87655B;
    margin-left: 10px;
}

.dateReservationArea {
    display: flex;
    align-items: center;
    margin-left: 10px;
}

.textReservationDate {
    font-family: "Mulish";
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    color: #1E202C;
    margin-left: 4px;
}