.containerTerms {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 24px 36px 24px 36px;
    gap: 24px;

}

.titleTerms {
    font-family: "Raleway";
    font-size: 17px;
    font-weight: 600;
    letter-spacing: 0em;
    text-align: left;
    color: #5F4840;
    left: 24px;
    height: 40px;
}

.update {
    font-family: "Mulish";
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    font-weight: 600;
    color: #1E202C;
}

.conditions {
    font-family: "Mulish";
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #1E202C;
}

.textTerms {
    font-family: "Mulish";
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: justify;
    font-weight: 400;
}

.checkTerms {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.textCheckConditions {
    font-family: "Mulish";
    margin-left: 12px;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    color: #353535;
}

.buttonsTermsArea{
    display: flex;
    flex-direction: row;
    gap: 16px;
}

.buttonCancel {
    display: flex;
    justify-content: center;
    height: 40px;
    width: 100%;
    border: 1px solid #E4E4E4;
    cursor: pointer;
    background: none;
    font-family: "Mulish";
    font-size: 13px;
    font-weight: 600;
    text-align: left;
    color: #353535;
    padding: 12px 20px;
}

.buttonContinue{
    display: flex;
    justify-content: center;
    height: 40px;
    width: 100%;
    border: 1px solid #D6A191;
    cursor: pointer;
    background: #D6A191;
    font-family: "Mulish";
    font-size: 13px;
    font-weight: 600;
    text-align: left;
    color: #FFFF;
    padding: 12px 20px;
}

.buttonContinue:disabled{
    display: flex;
    justify-content: center;
    height: 40px;
    width: 100%;
    border: 1px solid #EFDED9;
    cursor: pointer;
    background: #EFDED9;
    font-family: "Mulish";
    font-size: 13px;
    font-weight: 600;
    text-align: left;
    color: #A57C70;
    padding: 12px 20px;
}