*{
    scroll-behavior: smooth !important;
}
.react-calendar__tile:disabled{
    position: relative;
    background-color: #E4E4E4;
    color: #353535;
    opacity: 0.4;
}
.react-calendar__tile:disabled::after{
    content: "";
    position: absolute;
    top: 9px;
    left: -11px;
    right: -4px;
    height: 1em;
    border-top: 1px solid black;
    z-index: -1;
    transform: rotate(135deg);
}
.react-calendar__tile{
    color: #353535;
}
@media (max-height: 750px) {
    .react-calendar__tile {
        padding: unset !important;
        line-height: unset !important;
    }

    .react-calendar__navigation {
        height: unset !important;
        margin-bottom: unset !important;
    }

    .cardContainer {
        background-color: white;
        width: 60.766%;
        padding: 0% 3%;
    }
}


.react-calendar__navigation__label__labelText--from{
    font-family: Raleway;
    font-size: 23px;
    font-weight: 700;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
    color: #353535;
}
.react-calendar__navigation__prev-button{
    background: url(../src/assets/icons/arrowPreviousCalendar.svg) no-repeat !important;
    background-position: center !important;
}
.react-calendar__navigation__next-button{
    background: url(../src/assets/icons/arrowNextCalendar.svg) no-repeat !important;
    background-position: center !important;
}
.react-calendar__navigation button {
    min-width: 39px !important;
}
.react-calendar{
    max-width: 337px !important;
    width: 100% !important;
    border:unset !important;
    box-shadow: 0px 6px 8px rgba(5, 16, 55, 0.15) !important;
}
.react-calendar__viewContainer{
    padding: 0 22px 16px 23px;
}
.react-calendar__navigation{
    padding: 8px 22px 0 23px;
}
.react-calendar__tile--range{
    color: white !important;
}
.react-calendar__month-view__days__day--weekend:disabled > abbr{
    color: rgba(16, 16, 16, 0.3) !important;
}
.react-calendar__month-view__days__day--weekend{
    color: black !important;
}
.react-calendar__month-view__weekdays{
    justify-content: space-between;
}
.react-calendar__month-view__weekdays__weekday{
    padding: unset !important;
    line-height: unset !important;
    width: 40px;
    height: 40px;
    flex: 0 0 40px !important; 
}
.react-calendar__month-view__weekdays__weekday > abbr{
    text-decoration: none !important;

    font-family: Mulish;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    color: #B6B6B6;

}
.react-calendar__month-view__days__day--neighboringMonth {
    color: #757575 !important;
}
.react-calendar__tile {
    padding: unset !important;
    line-height: unset !important;
    width: 40px;
    height: 40px;
    /* flex: 0 0 40px !important; */
}
.react-calendar__tile--now {
    background: white !important;
}
.react-calendar__navigation__prev2-button{
    display: none;
}
.react-calendar__navigation__next2-button{
    display: none;
}
.react-calendar__tile--active{
    background: #C7CECC !important;
    color: #434A48 !important;
}
.react-calendar__tile--hasActive {
    background: #C7CECC !important;
    color: #434A48 !important;
}
.react-calendar_title{
    padding: unset !important;
    height: 40px !important;
}

.cardContainer {
    background-color: white;
    max-width: 376px;
    padding: 5.556% 4.167%
}


.cardTitle {
    font-family: Raleway;
    font-size: 23px;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
    color: #000000;
    display: block;
    padding-bottom: 6.86%;
}


.primaryButton {
    border: solid 1px;
    /* border-radius: 8px; */
    background-color: #000;
    color: #fff;
    padding: 4.575% 6.86%;
    cursor: pointer;
    width: 100%;
    height: 52px;
    font-family: Mulish;
    font-size: 16px;
    /* font-weight: 600; */
    line-height: 20px;
    letter-spacing: 0em;
    display: flex;
    justify-content: center;
    align-items: center;
}

.primaryButton:hover{
    background-color: #353535;
}
.primaryButton:active{
    background-color: #353535;
}

.primaryButton:disabled{
    background-color: #949494;
}

.socialMediaButton {
    background-color: #FFFFFF;
    color: #4F4F4F;
    cursor: pointer;
    width: 99%;
    height: 52px;
    font-family: Mulish;
    font-size: 16px;
    /* font-weight: 600; */
    line-height: 20px;
    letter-spacing: 0em;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #4F4F4F;
    gap: 8px;
}

.socialMediaButton:hover {
    background-color: #F4F4F4;
}

.spanTopInputs{
    font-family: Raleway;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
}

.inputTypeText{
    box-sizing: border-box;
    width: 100%;
    height: 46px;
    padding: 16px;
    font-size: 16px;
    background: #F2F2F2;
    border: none;
    margin-top: 7px;
    font-family: 'Mulish';
}
.inputTypeText::placeholder{
    color: #949494;
    font-size: 15px;
}
.inputTypeText:not(:placeholder-shown) {
    background: #FFFFFF;
    box-shadow: 0px 0px 1px 0px #32324733;
    box-shadow: 0px 1px 2px 0px #32324714;
}
.inputTypeText:focus{
    background: #FFFFFF;
    outline: none;
    color: #686868;
    font-family: 'Mulish';
    font-size: 15px;
    border: 1px solid #732130;
}

/*32 pixel when 1440x1240 else gonna be adjustable*/
.marginBottomType1{
    margin-bottom: 8.315%;
}

.inputError{
    border: solid 1px #DF585F;
}
.success{
    border: solid 1px #3DA558 !important;
}

.inputError::placeholder{
    color: #DF585F;
}

.inputError:focus{
    background: #FFFFFF;
    outline: none;
    border: 1px solid #DF585F;
}

.buttonIcon {
    border: none;
    cursor: pointer;
    background: none;
    padding: 24px;
}



/* div */
.search-box {
    background: transparent;
    height: 18px;
    border-radius: 50px;
}
  /* input */
.search-input {
    outline: none;
    border: none;
    background: none;
    width: 0;
    padding: 0;
    float: left;
    transition: .3s;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #5F4840;
    border-bottom: 1px solid #333;
}
.search-input::placeholder {
    color: #5F4840;
}
  /* icon */
.search-btn {
    color: #fff;
    float: left;
    width: 18px;
    height: 18px;
    border-radius: 50px;
    margin-right: 5px;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    transition: .3s;
}
.search-input:focus,
.search-input:not(:placeholder-shown) {
    width: 70px;
    padding: 0 6px;
}
.search-box:hover > .search-input {
    width: 70px;
    padding: 0 6px;
}
.search-box:hover > .search-btn,
.search-input:focus + .search-btn,
.search-input:not(:placeholder-shown) + .search-btn {
    background: transparent;
    color: #cd595a;
}
.demo-editor {
    height: 275px !important;
    border: 1px solid #F1F1F1 !important;
    padding: 5px !important;
    border-radius: 2px !important;
}
.demo-wrapper {
    width: 100% !important;
    display: block !important;
    margin-bottom: 25px !important;
    height: 400px !important;
}

[data-rmiz-modal-overlay="visible"]{
    background-color: #000 !important;
}
.DraftEditor-root {
    line-height: 18px !important;
}

/** Stock **/
.d-flex {
    display: flex;
}

.flex-row {
    flex-direction: row;
}

.flex-col {
    flex-direction: column;
}

.justify-content-center {
    justify-content: center;
} 

.align-center {
    align-items: center;
}

.mt-1 {
    margin-top: 1rem;
}

.rdt_TableHeadRow, .rdt_TableHeadRow, .rdt_TableCol {
    background-color: #FAF6F5;
    box-shadow: 0px -1px 0px 0px #EDF2F7 inset;
    height: 52px;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    color: #353535;
    text-transform: uppercase;
}

.rdt_TableCell {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;
    color: #353535;
    box-shadow: 0px -1px 0px 0px #EDF2F7 inset;
}

.image-column {
    padding-top: 5px;
}

.pointer {
    cursor: pointer;
}

.DraftEditor-root {
    background-color: white;
    padding: 12px;
    font-size: 12px;
    line-height: 6px;
    min-height: 100px;
    box-shadow: 0px 1px 2px rgb(50 50 71 / 8%), 0px 0px 1px rgb(50 50 71 / 20%);
}

.form-order::-webkit-scrollbar {
    width: 5px;
    border-radius: 13px;
    background-clip: padding-box;
    background-color: #aaa;
}
.form-order::-webkit-scrollbar-track {
    border-radius: 13px;
    background: #aaa;
}
.form-order::-webkit-scrollbar-thumb {        
    box-shadow: inset 0 0 0 10px;
    border-radius: 13px;
    background: #aaa;
}

/** Antd Tabs */
.ant-tabs-nav-list {
    align-items: flex-end;
    padding-top: 12px;
}

.ant-tabs-tab {
    border: 1px solid #f4f4f4 !important;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    padding-left: 24px !important;
    padding-right: 24px !important;
    box-shadow: 2px 0px 4px 0px rgba(120,120,120,0.50);
    max-height: 42px;
    font-size: 18px !important;
}

.ant-tabs-tab+.ant-tabs-tab {
    margin: 0 0 0 2px !important;
}