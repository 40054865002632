.BackdropContainer{
    position: fixed;
    top: 70px;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1100;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    transition: all 0.3s;
}

.ScrollContainer{
    position: absolute;
    display: flex;
    justify-content: center;
    width: 100%;
    height: auto;
    overflow-y: auto;
}
.BackdropContainerHeightTop{
    position: fixed;
    top: 64px;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1100;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background: #FAF6F5 !important;
    border-width: 1px 0px;
    border-style: solid;
    border-color: #D1D1D1 !important;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15) !important;
}
.ScrollContainerFullHeight{
    height: 100% !important;
}
.BackdropContainerCenter{
    top: 0px;
    align-items: center;
}
.BackdropContainerCenter > .ScrollContainerFullHeight{
    height: auto !important;
}
.BackdropContainerFull{
    position: fixed;
    top: 0 !important;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2100 !important;
    height: 100%;
    background: rgba(0, 0, 0);
    transition: all 0.3s;
}