.rentsDescriptionArea {
    display: flex;
    flex-direction: row;
    margin-left: 22.5px;
    margin-top: 12px;
}
.rentsDescriptionArea img{
    width: 35px;
}
.faqRentsAct {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #FFFF;
    color: #353535;
    cursor: pointer;
    padding: 12px 19px 12px 24px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    margin-top: 32px;
    font-family: "Raleway";
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
}

.rentsArea {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.modelSpan {
    font-family: "Raleway";
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: #87655B;
    margin-left: 10px;
}

.dateRentsnArea{
    display: flex;
    align-items: center;
    margin-left: 10px;
}

.textRentsDate {
    font-family: "Mulish";
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    color: #1E202C;
    margin-left: 4px;
}