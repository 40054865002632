.container {
    background-color: #FFF;
}
.topLeftArea{
    display: flex;
    flex-direction: column;
}
.rowsSections{
    display: flex;
    justify-content: space-between;
}
.nav {
    grid-area: nav;
}
.section1 {
    width: 409px;
    background-color: #FAFAFA;
    padding: 12px;
}
.section2 {
    width: 70%;
    height: fit-content;
    background-color: #FFFF;
    margin-bottom: 100px;
}
.topSection {
    display: flex;
    justify-content: space-between;
    padding: 0px 107px 0px 64px;  
    margin-top: 56px;
}
.modelsForEachPage {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;
    margin-bottom: 32px;
}
.qtyModels {
    height: 40px;
    width: 84px;
    margin: 1px;
    padding: 10px;
    border: none;
    padding: 10px;
    box-shadow: 0px 2px 4px 0px #0510370F;
}

.filtersArea {
    display: flex;
    column-gap: 24px;
    margin-top: 16px;
}

.titleStyles {
    font-family: "Raleway";
    font-size: 48px;
    font-weight: 400;
    line-height: 56px;
    letter-spacing: 0em;
    text-align: left;
    color: #5F4840;
}

.filterItem {
    height: 22px;
    display: flex;
    align-items: center;
    height: 22px;
    margin-right: 24px;
    padding: 5px;
    left: 0px;
    top: 0px;
    border-radius: 0px;
    padding: 2px, 8px, 2px, 8px;
    background-color: #C7CECC;
    padding: 0px 8px;
    color: #434A48;
    font-family: Mulish;
    font-size: 11px;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: center;
    display: flex;
    align-items: center;
    margin: 0px 5px;
}

.close {
    border: none;
    cursor: pointer;
    background: none;
}

.titlePaginationModels {
    font-family: "Raleway";
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: right;
    color:#5F4840;
    margin-right: 8px;
}

.paginationTop{
    display: flex;
    align-items: center;
}

ul.pagination {
    display: inline-block;
    padding: 0;
    margin: 0;
    height: 40px;
    box-shadow: 0px 2px 4px 0px #0510370F;
}

ul.pagination li {
    display: inline;
}

ul.pagination li a {
    color: black;
    float: left;
    padding: 0px 16px;
    text-decoration: none;
}

.allDressSearchContainer {
    display: flex;
    column-gap: 48px;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 10px;
    padding: 0px 64px;
}

.paginationWrapper{
    display: flex;
    justify-content: flex-end;
    margin: 0 16px;
    padding: 16px 0;
    align-items: center;
    gap: 8px;
}
.spanPage{
    font-family: Raleway;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: right;
    color:#5F4840;
}
.paginationContainer{
    display: flex;
    align-items: center;
    padding: 8px 0;
    background-color: white;
    box-shadow: 0px 2px 4px rgba(5, 16, 55, 0.06);
}
.arrows{
    width: 9px;
    height: 9px;
    padding: 7.5px 9.5px;
}
.pages{
    display: flex;
    align-items: center;
    font-family: Mulish;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
}
.inlineWrap{
    display: flex;
    flex-wrap: wrap;
    margin-top: 16px;
    margin-bottom: 16px;
}