.slide1 {
    display: flex;
    flex-direction: row;
    gap: 121.25px;
    margin: 26px 14.75px 0px 17.5px;
    align-items: center;
}

.slide2 {
    display: flex;
    flex-direction: row;
    gap: 121.25px;
    margin: 26px 14.75px 0px 17.5px;
    align-items: center;
}

.modalTitle {
    font-family: "Raleway";
    font-size: 17px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #5F4840;
    margin-bottom: 18px;
}


.welcomeTitle {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 20px;
    color: #5F4840;
}

.buttonCloseModal {
    border: none;
    cursor: pointer;
    background: none;
    width: 13.5px;
    height: 13.5px;
    position: absolute;
    top: 13px;
    right: 45px;
}

.welcomeItems {
    display: flex;
    flex-direction: column;
}

.textContent {
    display: flex;
    flex-direction: row;
    gap: 175.25px;
    margin: 26px 14.75px 20px 17.5px;
    align-items: center;
}

.subTitleText {
    font-family: "Raleway";
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: #353535;
    width: 120px;
}

.textWelcome {
    font-family: "Mulish";
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #353535;
    margin: 0px 97px 0px 17px;
}

.slider {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 16px;
    margin-bottom: 17px;
}

.welcome1 {
    width: 100%;
    margin-bottom: 5px;
}

.welcome2 {
    width: 100%;
}

.buttonSlider {
    border: none;
    cursor: pointer;
    background: none;
}

.rentNowWelcome {
    height: 40px;
    width: 288px;
    left: 0px;
    top: 0px;
    border-radius: 0px;
    padding: 12px 20px 12px 20px;
    background-color: #87655B;
    font-family: "Mulish";
    font-style: normal;
    line-height: 16px;
    font-size: 13px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: center;
    color: #FFFFFF;
    border: none;
}

.bottomLink {
    display: flex;
    justify-content: center;
    margin-top: 16px;
    margin-bottom: 24px;
    font-family: "Raleway";
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: center;
    color: #5F6867;
    text-decoration: underline;
}