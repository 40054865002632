.collapsible {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: transparent;
    color: #1E202C;
    cursor: pointer;
    padding: 12px 19px 12px 24px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    margin-top: 32px;
}
.collapsible span {
    font-family: "Raleway";
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
}

.container {
    color: rgb(0, 0, 0);
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.display {
    margin-top: 6px;
    margin-bottom: 24px;
}

.display > * {
    animation: 2s both maximishHeight, 0.5s both fadeIn;
}

.hidden {
    margin-top: 12px;
}

.hidden > * {
    animation: 1s both fadeOut, 0.5s both diminishHeight;
}

.flexColumCenterContent{
    display: flex;
    flex-flow: column;
    align-items: center;
    overflow-x: hidden;
}

.checkContainer{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    padding-left: 6%;
    column-gap: 16px;
}
.checkboxInline{
    width: 40%;
    padding-right: 12px;
}

.spanCheck{

font-family: Mulish;
font-size: 16px;
font-weight: 500;
line-height: 20px;
letter-spacing: 0em;
text-align: left;

}


@keyframes diminishHeight {
    from {
        max-height: 180px;
        opacity: 1;
    }

    to {
        max-height: 0px;
        opacity: 0;
    }
}

@keyframes maximishHeight {
    from {
        max-height: 0px;
    }

    to {
        max-height: 1000px;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

p{margin: 0;}

@media (max-width: 774px){
    .collapsible{
        background-color: transparent;
        padding: 0px 20px;
    }
    .display {
        width: 100%;
        display: block;
        margin-left: 0px;
        margin-top: 24px;
    }
}