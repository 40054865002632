.buttonSizeContainer {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    margin: 12px 26px 16px;
}

.buttonSize {
    padding: 0px 3%;
    border: none;
    background-color: transparent;
    color: #454A5F;
    cursor: pointer;
    transition-duration: 0.4s;
    height: 38px;
    width: fit-content;

    font-family: Mulish;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;

}


.buttonSize:hover:disabled {
    background-color: #FFFF;
    color: #454A5F;
}
.buttonSize:disabled {
    cursor: auto;
    opacity: 0.4;
}

.buttonSize:hover {
    background-color: #D6A191;
    color: #FFFF;
}


.selected{
    background-color: #D6A191;
    color: #FFFF;
}

.sizeCategory {
    font-family: 'Mulish';
    font-weight: 600;
    font-size: 13px;
    line-height: 16.32px;
    color: #000000;
    margin-left: 24px;
}