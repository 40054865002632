.title{
    color: #5F4840;
    font-size: 24px;
    font-weight: 400;
    font-family: 'Raleway';
    line-height: 28.18px;
    margin: 0;
    font-style: normal;
}
.description{
    font-size: 14px;
    color: #353535;
    font-family: 'Mulish';
    font-weight: 400;
    line-height: 17.57px;
    margin-top: 2px;
}
.linking{
    text-decoration: underline;
    color: #87655B;
    font-size: 14px;
    font-family: 'Raleway';
    line-height: 16.44px;
    font-weight: 500;
    cursor: pointer;
}
.styleSpanSmall{
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 13px;
    display: flex;
    align-items: center;
    color: #949494;
    margin-top: 8px;
}