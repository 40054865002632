.subTitleContainer {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

.collapsableHeader {
  display: flex;
  justify-content: center;
  margin-bottom: 48px;
}

.subTitle {
  font-family: 'Poppins';
    font-size: 22px;
    font-weight: 400;
    line-height: 33px;
    color: #353535;
  letter-spacing: 0em;
  text-align: left;
  display: block;
}

.nextButton {
  border: solid 1px;
  border-radius: 8px;
  border-color: #D6A191;
  background-color: #FFFF;
  color: #D6A191;
  padding: 4.575% 6.86%;
  margin-top: 5%;
  cursor: pointer;
}

.showHideButton {
  border: none;
  cursor: pointer;
  background: none;
}

.eventArea {
  display: flex;
  justify-content: center;
}

.eventContainer {
  display: flex;
  flex-flow: column;
  width: 50%;
  margin: 2.2%;
}


.container {
  margin-bottom: 20%;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: Mulish;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  border: solid 1.5px #FFFF;
  cursor: pointer;
  background: none;
  display: flex;
  flex-flow: column;
  align-items: center;
  height: 80px;
  width: 124px;
  top: 0px;
  border-radius: 0px;
  padding: 8px;
  transition-duration: 0.5s;
  color: #353535;
}

.container:hover {
  border: solid 1.5px #D6A191;
}
.selected{
  border: solid 1.5px #D6A191;
}

.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  width: 20px;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #FFFF;
  border: solid 2px;
  border-radius: 5px;
  border-color: #b1b1b1;
}

.container:hover input~.checkmark {
  background-color: rgb(212, 212, 212);
}

.container input:checked~.checkmark {
  border: solid 2px;
  border-radius: 5px;
  border-color: #D6A191;
  background-color: #D6A191;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.container input:checked~.checkmark:after {
  display: block;
}

.container .checkmark:after {
  left: 5px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}


.eventIcons {
  color: #a57c70;
  margin-bottom: 14px;
}