.row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 64px;
}
.col{
    max-width: 637px;
}
.col img{
    max-width: 557px;
}
.col p, .colMax p{
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 35px;
    text-align: justify;
    color: #353535;
}
.col h1, .colMax h1{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 72px;
    color: #353535;
}
.colMax{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 80px 64px;
    gap: 64px;
}

.rowSm{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 48px;
    gap: 48px;
}

.colSm img{
    max-width: 100%;
}
.colMaxSm{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 80px 44px;
    gap: 34px;
}
.colMaxSm p{
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
}

@media (max-width: 1324px) {
    .col img{
        max-width: 327px;
    }
    .col{
        max-width: 437px;
    }
    .col p, .colMax p{
        font-size: 18px !important;
    }
    .col h1, .colMax h1{
        font-size: 36px !important;
    }
}