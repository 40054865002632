.containerError{
    display: flex;
    align-items: center;
    background-color: #DF585F;
    padding: 16px;
    max-height: 60px;
}
.buttonClose{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    border-radius: 50px;
    background-color: #A02C47;
}
.textDescriptionError{
    color: #FFF;
    width: 82%;
    margin-left: 16px;
}