#root{
  height: 100vh;
}

figure{
  margin: 0px;
}

body{
  margin: 0px
}



