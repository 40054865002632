.container{
    display: flex;
    flex-direction: column;
    padding: 16px 22px;
}
.containerCart{
    margin: 24px 0px;
}
.collapsible {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: transparent;
    color: #1E202C;
    cursor: pointer;
    padding: 12px 19px 12px 24px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    margin-top: 32px;
}
.collapsible span {
    font-family: "Raleway";
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
}
.display{
    display: block;
}

.hidden{
    display: none;
}