.needMoreDays{
    margin-top: 16px;
    overflow-x: hidden;
}
.spanNeedMoreDays{
    font-family: Raleway;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
    color: #000;
    text-decoration: underline;
    cursor: pointer;
}