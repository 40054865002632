.styleInput{
    background: #FFFFFF;
    height: 36px;
    width: 92%;
    border-radius: 5px;
    border: none;
    box-shadow: 0px 1px 2px rgba(50, 50, 71, 0.08), 0px 0px 1px rgba(50, 50, 71, 0.2);
    font-size: 12px;
    padding-left: 12px;
    padding-right: 12px;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;
}
.styleInput::placeholder{
    color: #949494;
    font-size: 12px;
    font-family: 'Mulish';
    font-weight: 400;
    line-height: 15.06px;
}

.labelInput{
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #686868;
    padding: 0;
    margin-bottom: 10px;
}
.containerInputSearch{
    display: flex;
    align-items: center;
    background: transparent;
    border: none;
    height: 32px !important;
    padding: 5px 15.5px;
}
.containerInputSearch input{
    width: 100%;
    height: 100%;
    border: none;
    box-shadow: none !important;
    outline: none !important;
    background-color: transparent !important;
    border-bottom: 1px solid #000;
    padding-left: 10px;
}
.containerInputSearch input::placeholder{
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #B5B5BD;
}