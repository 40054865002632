.containtCoupon{
    display: flex;
    align-items: center;
    background: #F2F2F2;
    border: 1px solid #F2F2F2;
    height: 46px;
    margin: 10px 0px;
}
.button{
    padding: 12px 20px 12px 20px;
    height: 100%;
    width: 30%;
    background-color: #E4E4E4;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    text-transform: uppercase;
    color: #353535;
    border: none;
    cursor: pointer;
}
.input{
    width: 70%;
    border: none;
    padding: 16px;
    height: 100%;
    background-color: transparent;
}
.input:focus-visible{
    border: none;
    outline: none !important;
}