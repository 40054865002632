.containerModal{
    width: 80% !important;
    max-width: 80% !important;
    height: auto;
    background-color: #FFF;
    padding: 10px 16px;
}
.textModalTitle{
    font-size: 17px;
    color: #5F4840;
    text-align: center;
    margin-top: 10px !important;
    margin-bottom: 20px !important;
}
.titleModal{
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 20px;
    color: #5F4840;
}
.textDescription{
    font-size: 14px;
    color: #353535;
    text-align: center;
    margin-bottom: 20px;
}
.textCenter{
    display: flex;
    text-align: center;
    justify-content: center;
    position: relative;
}
.containCheck{
    display: flex;
    align-items: center;
    margin-top: 12px;
    margin-bottom: 22px;
}
.containButtons{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.buttonMiddle{
    width: 45% !important;
}
.buttonDanger{
    background-color: #BF4052 !important;
}
.buttonPrimary {
    background-color: #87655B !important;
}
.inlineContainer{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.containerInputs{
    margin-top: 18px;
    margin-bottom: 14px;
}
.containerFilters{
    margin: 36px 36px;
    height: 100%;
}
.titleFilters{
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 21px;
    color: #5F4840 !important;
}
.inlineContainer{
    display: flex;
    align-items: center;
    justify-content: right;
}
.centerContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.containButtonsColumn{
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}
.containButtonsColumn div{
    margin-top: 8px;
}
.containerColor{
    background-color: #FAF6F5;
    width: 100%;
    padding: 12px;
}

.itemSidebar{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 22px 34px;
    border-bottom: 1px solid rgba(225, 218, 211, 1)
}
.itemsLabel{
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #353535;
}
.itemsLabelRed{
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: rgba(191, 64, 82, 1) !important;
}
.containerFiltersPadding{
    background-color: #FAFAFA;
    padding: 35px 12px;
    width: 100%;
}

.loaderSpinner{
    border: 3px solid #FFF;
    border-top: 3px solid #C6C8C7;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    animation: spin 1s linear infinite;
    margin-right: 10px;
}

.textContainer {
    margin-top: 48px;
    padding: 0 24px;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
}
.modalContainter {
    margin-top: 48px;
    padding: 0 24px 12px;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    max-height: 60vh;
    overflow-y: auto;
}
.spaceBetween {
    display: flex;
    justify-content: space-between;
    gap: 12px;
}
.rowContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 24px;
    justify-content: space-between;
}
.columnContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
}
.rowContainerLeft {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 6px;
    justify-content: flex-start;
    font-size: 12px;
}
.inputSections {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    flex: 1;
}
.calendarSection {
    display: flex;
    flex-direction: row;
    max-width: 100%;
    flex: 1;
    justify-content: center;
}
.itemInput {
    background-color: #FFFFFF;
    border-radius: 0px !important;
    width: 100% !important;
}
.itemInput1 {
    background-color: #FFF;
    border-radius: 0px !important;
}
.itemInputSelect {
    background-color: #fff;
    box-shadow: 0px 0px 1px 0px #32324733;
    border: none;
    height: 36px;
    border-radius: 0px;
}

.itemInput:disabled, .itemInput1:disabled, .itemInputSelect:disabled, .itemInput2:disabled{
    background-color: #F2F2F2;
}
.itemInput:focus, .itemInput1:focus, .itemInputSelect:focus, .itemInput2:focus{
    border: 1px solid #732130;
}

.numberAndCode{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 12px 0px 0px 0px;
    max-width: 100%;
}

@keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
}

@media (min-width: 774px){
    .containerModal{
        width: 328px !important;
        max-width: 328px !important;
    }
}

.rotateCenter {
	-webkit-animation: rotate-center 6s linear infinite both;
	        animation: rotate-center 6s linear infinite both;
}
@-webkit-keyframes rotate-center {
    0% {
      -webkit-transform: rotate(0);
              transform: rotate(0);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
}
@keyframes rotate-center {
    0% {
      -webkit-transform: rotate(0);
              transform: rotate(0);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
}