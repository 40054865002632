.spanTitle{
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #353535;
}
.containerPriceElement{
    padding: 0px 24px;
}
.priceArea{
    margin-top: 16px;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #1E202C;
}
.inlineContaint{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 8px;
}
.priceDescription{
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #1E202C;
}

.customFormControl{
    display: flex;
    align-items: center;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 15px;
    color: #353535;
}
.customFormControl::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    background-color: #05103300;
    box-shadow: inset 1em 1em #05103300;
}
.customFormControl input{
    width: 20px;
    height: 20px;
    border: 0.15em solid #05103300;
    border-radius: 50%;
    transform: translateY(-0.075em);
}
.customFormControl:checked::before {
    transform: scale(1);
}
.customContainer{
    margin-top: 0px !important;
    display: flex;
    align-items: center;
}