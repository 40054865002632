.presentationImg img{
    width: 100%;
    height: 136px;
}
.title{
    font-family: 'Raleway' !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 17px !important;
    line-height: 20px !important;
    text-align: center;
    color: #27272E !important;
}
.containerFaq{
    padding: 14px 12px;
}
.subtitle{
    font-family: 'Mulish' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 18px !important;
    color: #425466 !important;
    text-align: center;
    margin-top: 12px;
    margin-bottom: 12px;
}
.containtSeach{
    padding: 0px 36px;
}
.buttonFAQ{
    margin-top: 18px;
    margin-bottom: 16px;
    border: 1px solid rgba(135, 101, 91, 1) !important;
}
.textButtonFAQ{
    color: rgba(135, 101, 91, 1) !important;
}
.subtitleItems{
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: #353535;
}
.itemsFAQ{
    padding: 12px 24px;
    margin-bottom:  10px;
    margin-top: 10px;
}

@media (min-width: 774px){
    .containerDesktop{
        margin: 0 auto;
        max-width: 668px;
    }
}
.containImgBanner{
    width: 100%;
    max-height: 138px;
    object-fit: cover;
}