.collapsible {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #F7EFED;
    color: #1E202C;
    cursor: pointer;
    padding: 12px 19px 12px 24px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    margin-top: 32px;
}
.collapsible span {
    font-family: "Raleway";
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
}
.container {
    display: none;
    margin: 0px 0px 15px 0px;
    padding: 0px 24px;
    overflow-y: hidden;
}
.display{
    display: block;
}

.hidden{
    display: none;
}
.buttonToggle{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #FFFF;
    color: #353535;
    cursor: pointer;
    padding: 12px 19px 12px 24px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    margin-top: 32px;
    font-family: "Raleway";
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
}