.container {
    overflow-y: hidden;
}
.display {
    display: block;
    width: 83%;
}
.hidden > * {
    display: none;
}
@media (max-width: 774px){
    .display {
        display: block;
        width: 100%;
        margin-left: 0px;
        margin-top: 24px;
    }
}
.container span{
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #353535;
}
.faqs {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    color: #353535;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #FFFF;
    cursor: pointer;
    width: 100%;
    border: none;
    outline: none;
    margin-top: 32px;
    margin-bottom: 12px;
    text-align: left;
    text-transform: uppercase;
}