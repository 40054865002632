.navContainer {
    display: flex;
    align-items: center;
    height: 72px;
    left: 0px;
    top: 0px;
    border-radius: 0px;
    padding: 0px 53px;
    background-color: #fff;
}

.news {
    font-family: 'Raleway';
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #000;
    padding: 10px;
    border: none;
    cursor: pointer;
    background: none;
    padding: 24px 0px 24px 0px;
    text-decoration: none;
}
.rent {
    font-family: 'Raleway';
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #000;
    padding: 10px;
    border: none;
    cursor: pointer;
    background: none;
    padding: 24px;
}

.newsRentArea {
    display: flex;
    flex-flow: row;
    gap: 24px;
    align-items: center;
}

.sorella img{
    width: 209px;
    height: 100%;
}

.containerButtonNav {
    display: flex;
    align-items: center;
    column-gap: 24px;
    justify-content: flex-end;
}

.buttonNav {
    border: none;
    cursor: pointer;
    background: none;
}

.buttonNavCount {
    font-family: 'Raleway';
    font-size: 14px;
    font-weight: 500;
    border: none;
    cursor: pointer;
    background: none;
    padding: 10px;
    color: #000;
}

.buttonNavLogin {
    font-family: 'Raleway';
    font-size: 19px;
    font-weight: 500;
    border: none;
    cursor: pointer;
    background: none;
    line-height: 22px;
    color: #000;
}
.containIcon{
    position: relative;
    padding: 0px 6px;
}
.containCountCart{
    position: absolute;
    top: -8px;
    right: 0px;
    background-color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 14px;
    width: 14px;
    border-radius: 10px;
}
.numberCountCart{
    color: #FFF;
    font-size: 12px;
    font-family: 'Raleway';
    padding: 0px;
    margin: 0px;
}