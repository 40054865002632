.buttonShareContainer {
    display: flex;
    flex-flow: row;
    justify-content:space-around;
    padding: 12px 24px;
}
.container{
    width: 100%;
    padding: 0px;
}
.designDisplay{
    background-color: transparent !important;
    font-family: "Raleway" !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    line-height: 19px !important;
    letter-spacing: 0em !important;
    text-align: left !important;
}
.buttonShare {
    padding: 0px 3%;
    border: none;
    background-color: transparent;
    color: #454A5F;
    cursor: pointer;
    transition-duration: 0.4s;
    height: 38px;
    width: fit-content;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
}

.buttonShare:hover:disabled {
    background-color: #FFFF;
    color: #454A5F;
}
.buttonShare:disabled {
    cursor: auto;
    opacity: 0.4;
}

.buttonShare:hover {
    background-color: #D6A191;
    color: #FFFF;
}


.selected{
    background-color: #D6A191;
    color: #FFFF;
}