.subTitleContainer {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    
}

.collapsableHeader {
    display: flex;
    justify-content: center;
    margin-bottom: 48px;
}



.subTitle {
    font-family: 'Poppins';
    font-size: 22px;
    font-weight: 400;
    line-height: 33px;
    color: #353535;
    letter-spacing: 0em;
    text-align: left;
    display: block;

}

.nextButton {
    border: solid 1px;
    border-radius: 8px;
    border-color: #D6A191;
    background-color: #FFFF;
    color: #D6A191;
    padding: 4.575% 6.86%;
    margin-top: 5%;
    cursor: pointer;
}

.showHideButton{
    border: none;
    cursor: pointer;
    background: none;
}
