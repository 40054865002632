.d-flex {
    display: flex;
}

.justify-content-center {
    justify-content: center;
}

.justify-content-between {
    justify-content: space-between;
}

.align-items-center {
    align-items: center;
}

.mt-1 {
    margin-top: 1rem;
}

.rdt_TableHeadRow, .rdt_TableHeadRow, .rdt_TableCol {
    background-color: #FAF6F5;
    box-shadow: 0px -1px 0px 0px #EDF2F7 inset;
    height: 52px;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    color: #353535;
    text-transform: uppercase;
}

.rdt_TableCell {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;
    color: #353535;
    box-shadow: 0px -1px 0px 0px #EDF2F7 inset;
}

.image-column {
    padding-top: 5px;
}

.pointer {
    cursor: pointer;
}