.buttonPrimary{
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
    background-color: #000;
    border: none;
    cursor: pointer;
    color: #fff;
    font-family: 'Mulish';
}
.buttonPrimary:hover:enabled{
    background-color: #353535;
}
.buttonDisabled{
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
    background-color: #949494;
    color: #000;
    border: none;
}
.buttonText{
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    text-transform: uppercase;
    color: #FFFFFF;
}
.textOutline{
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    text-transform: uppercase;
    color: #353535;
}
.buttonOutline{
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
    background-color: transparent;
    border: 1px solid #87655B;
    cursor: pointer;
}
.buttonOutline:hover{
    background-color: #87655B;
}
.buttonOutline:hover .textOutline{
    color: #FFFFFF;
}
.buttonOutline:disabled{
    opacity: 20%;
}