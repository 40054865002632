.inputGroup {
    background: #FFFFFF;
    box-shadow: 0px 1px 2px rgba(50, 50, 71, 0.08), 0px 0px 1px rgba(50, 50, 71, 0.2);
    padding: 11px 10px;
}

.inputGroup::before {
    content: '$';
}

.invalid {
    box-shadow: inset 0 0 0 2px red;
}

.inputGroup input {
    text-align: right;
    border: none;
    width: 85%;
}

.inputGroup input:focus,
.inputGroup input:focus-visible {
    border: none;
    outline: none;
}