.colorPicker {
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px;
  width: 46px;
  height: 46px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: #C1DBD9;
  cursor: pointer;
}

.pressColorPicker {
  /*box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);*/
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: #C1DBD9;
  cursor: pointer;
}

.wrapperPressColorPicker { /*this is the style that makes to look pushed*/
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 46px;
  height: 46px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}