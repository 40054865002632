.cardContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 12px;
    border-radius: 10px;
    width: 100%;
    max-width: 284px;
    min-height: 100px;
    color: #F4F4F4;
    padding: 24px 24px 12px 24px;
    font-family: Mulish;
    position: relative;
}

.visaCard {
    background: linear-gradient(135deg, rgba(26,31,113,1) 0%, rgba(20,52,203,1) 74%);
}

.masterCard {
    background: linear-gradient(135deg, rgba(0,0,0,1) 0%, rgba(40,39,39,1) 74%);
}

.cardNumber {
    font-size: 24px;
    width: 100%;
    letter-spacing: 4px;
    display: flex;
    justify-content: center;
}

.cardInfo {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.default {
    position: absolute;
    border-radius: 4px;
    background: linear-gradient(135deg, rgba(244,171,0,1) 0%, rgba(254,248,39,1) 74%);
    text-transform: uppercase;
    color: #1F1F1F;
    padding: 4px 8px;
    top: 8px;
    left: 8px;
    font-size: 12px;
    font-weight: 600;
}

.delete {
    position: absolute;
    top: 8px;
    right: 8px;
    cursor: pointer;
}