.container{
    display: block;
}
.nav {
    grid-area: nav;
    width: 100%;
}
.containerMobile{
    padding: 24px 16px;
}
.filterSvg{
    padding: 4.88px;
}
.spanFilter {
    font-family: Raleway;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: right;
    color: #5F4840;
}
.btnFilterWrapper{
    display: flex;
    gap: 8px;
    align-items: center;
}
.inlineContainer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 22px;
}
.tableResponsive{
    max-width: 100%;
    overflow-x: auto;
    max-height: auto;
    margin-top: 16px;
}