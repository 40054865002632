.needMoreDays{
    margin-top: 16px;
    overflow-x: hidden;
}
.spanNeedMoreDays{
    font-family: Raleway;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
    color: #87655B;
    text-decoration: underline;
    cursor: pointer;
}
.backgroundContainer{
    background-color: transparent !important;
}
.textDescription{
    font-family: 'Mulish';
    font-style: italic;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #1E202C;
}