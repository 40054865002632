.buttonSizeContainer {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    margin: 0px 26px;
}
.buttonSize {
    padding: 0px 3%;
    border: none;
    background-color: transparent;
    color: #434A48;
    cursor: pointer;
    transition-duration: 0.4s;
    height: 38px;
    font-family: Mulish;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    white-space: nowrap;
}
.buttonSize:hover:disabled {
    background-color: #FFFF;
    color: #434A48;
}
.buttonSize:disabled {
    cursor: auto;
    opacity: 0.4;
}
.buttonSize:hover {
    background-color: #C7CECC;
    color: #434A48;
}
.selected{
    background-color: #C7CECC;
    color: #434A48;
}