.allDressSearchContainer {
    display: flex;
    width:  100%;
    flex-direction: column;
    align-items: center;
    column-gap: 16px;
    justify-content: center;
    margin-top: 32px;
}
.modifySearchButton {
    height: 40px;
    width: 459px;
    left: 0px;
    margin-top: 70.73px;
    border-radius: 0px;
    padding: 12px 20px 12px 20px;
    border: 1px solid #87655B;
    font-family: "Mulish";
    font-size: 13px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: center;
    color: #87655B;
    background: none;
    cursor: pointer;
}
.imgEmpty{
    height: 559px;
}

@media (max-width: 774px){
    .allDressSearchContainer {
        display: flex;
        column-gap: 16px;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 32px;
    }
    .imgEmpty{
        height: 271px;
    }
    .modifySearchButton {
        height: 40px;
        width: 288px;
        left: 0px;
        margin-top: 70.73px;
        border-radius: 0px;
        padding: 12px 20px 12px 20px;
        border: 1px solid #87655B;
        font-family: "Mulish";
        font-size: 13px;
        font-weight: 600;
        cursor: pointer;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: center;
        color: #87655B;
        background: none;
    }
}