.mainImageDressContainer {
    height: 80vh; 
    width: calc(80vh * 9 / 16);
    position: relative;
    margin: 37px 43px 0px 43px;
    display: flex;
    justify-content: center;
}

.arrows {
    position: absolute;
    z-index: 9;
    display: flex;
    justify-content: space-between;
    align-items: center;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
}

.image{
    height: 80vh;
    width: auto;
    object-fit: cover;
}

.image > div > img {
    object-fit: cover;
    height: 80vh;
    width: auto;
}