.registerContainer{
    display: flex;
    flex-direction: column;
}
.titleCard{
    font-family: 'Raleway';
    font-size: 23px;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
    color: #87655B;
    display: flex;
    justify-content: center;
}

.forgetPassword{
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 14px;
    margin-top: 7px;
    display: flex;
    justify-content: flex-end;
    color: #949494;
    text-decoration: none;
}


.noAccountYetRegister{
    margin-top: 6.235%;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    color: #A57C70;
    text-decoration: none;
}

.errorMessage {
    font-family: Mulish;
    font-size: 11px;
    font-weight: 400;
    color: #DF585F;
}

.cardContainerMobile{
    position: absolute;
    width: 376px;
    padding: 32px 24px;;
    background-color: white;
}
.spanTermsAndConditions{
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 13px;
    text-align: center;
    color: #949494;
}
.linkTermsAndConditions{
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 13px;
    text-align: center;
    color: #949494;
}
.textSubtitle{
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #5F6867;
    margin: 24px 0px;
}

@media (max-width: 774px){
    .cardContainerMobile{
        width: 269px;
    }
}