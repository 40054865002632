.containerFavorites {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 24px 16px;
    gap: 24px;
}

.titleFavorites {
    font-family: "Raleway";
    font-size: 24px;
    font-weight: 400;
    letter-spacing: 0em;
    text-align: left;
    color: #5F4840;
    left: 24px;
    height: 40px;
    width: 288px;
    line-height: 28px;
    letter-spacing: 0em;
}

.filterTopContainer {
    display: flex;
    flex-direction: row;
    justify-content: left;
    gap: 131px;
}

.filtersButtons {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Raleway";
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: right;
    color: #5F4840;
    border: none;
    cursor: pointer;
    background: none;
    gap: 8px;
}

.dressFavoritesContainer {}

.allFavoritesContainer {
    display: flex;
    column-gap: 16px;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 10px;
}

.cardBackGround{
    margin-top: 16px;
    background-color: white;
    border-radius: 0px 0px 6px 6px;
}