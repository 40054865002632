.containerDesktop{
    display: flex;
    flex-direction: column;
    background-color: #FFF !important;
    min-width: 100%;
    padding: 37px 12px 0px 12px;
    box-sizing: border-box;
    margin-bottom: 54px;
}
.paddingContainer{
    padding: 24px;
    box-sizing: border-box;
}
.containerOptions{
    background-color: #FFF;
    padding: 0px 12px;
}
.containerColorPrimary{
    background-color: #F2F2F2;
    padding: 12px 0px 32px 0px;
    width: 100%;
}
.titleFilters {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 33px;
    text-align: center;
    color: #5F4840;
}

.addFavoritesArea {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 32px;
    padding: 0px 30px;
}

.rentButtonsArea{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.rentNowButton{
    border: none;
    cursor: pointer;
    height: 52px;
    width: 352px;
    padding: 16px 24px 16px 24px;
    background-color: #D6A191;
    color:#FFFF;
    font-family: "Mulish";
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
}

.bookingButton{
    cursor: pointer;
    height: 52px;
    width: 337px;
    border-radius: 0px;
    padding: 16px 24px 16px 24px;
    border: solid 1px #D6A191;
    font-family: "Mulish";
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    color:#D6A191;
    background-color: #F7EFED;
}

.descriptionContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 24px;
    padding: 0px 24px;
}

.addFavoritesSpan {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #1E202C;
}

.addFavoritesButton {
    border: none;
    cursor: pointer;
    background: none;
}

.heartBtn {
    position: absolute;
    top: 11.5px;
    right: 10.5px;
}

.dressDescription {
    font-family: "Mulish";
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    color:#1E202C;
}

.inlineContainer{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.containPadding{
    margin: 0px 36px;
    width: 90%;
}

.rentButtonsArea{
    padding: 0px 20px;
    align-items: center;
}

.rentNowButton{
    border: none;
    cursor: pointer;
    height: 52px;
    width: 337px;
    padding: 16px 24px 16px 24px;
    background-color: #D6A191;
    color:#FFFF;
    font-family: "Mulish";
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    margin-top: 20px;
}

.bookingButton{
    cursor: pointer;
    height: 52px;
    width: 337px;
    border-radius: 0px;
    padding: 16px 24px 16px 24px;
    border: solid 1px #D6A191;
    font-family: "Mulish";
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    color:#D6A191;
    background-color: #F7EFED;
    margin-top: 20px;
}

@media (max-width: 774px) {
    .rentNowButton{
        max-width: 100%;
        width: 100%;
    }
    .heartImg{
        width: 15.25px;
        height: 13.72px;
    }
    
    .bookingButton{
        max-width: 100%;
        width: 100%;
    }
}
.containButtons{
    width: 100%;
}
.buttonSpacing{
    margin-top: 16px;
}
.borderColorPrimary{
    border-color: #D6A191;
}
.textColorPrimary{
    color: #D6A191;
}
.containerCenter{
    display: flex;
    align-items: center;
    justify-content: center;
}
.textDescription{
    font-family: 'Mulish';
    font-style: italic;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #1E202C;
}
.notesSection {
    padding-top: 32px;
    padding-left: 24px;
    padding-right: 24px;
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.sectionTitle {
    font-family: "Raleway";
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0em;
    text-transform: uppercase;
}