.collapsible {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: transparent;
    color: #1E202C;
    cursor: pointer;
    padding: 12px 19px 12px 24px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    margin-top: 18px !important;
}
.collapsible span {
    font-family: "Raleway";
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #353535;
}
.container {
    display: none;
    padding: 0px 24px;
    overflow-y: hidden;
    justify-content: center;
}
.display{
    display: flex;
}
.hidden{
    display: none;
}
.buttonToggle{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: transparent;
    color: #353535;
    cursor: pointer;
    padding: 12px 19px 12px 24px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    margin-top: 18px;
    font-family: "Raleway";
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
}
.textPending{
    text-transform: uppercase;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: #5F6867;
    margin-right: 21px;
}
.textSelected {
    text-transform: uppercase;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: #5F6867;
    margin-right: 21px;
}