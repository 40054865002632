.formControl{
    display: flex;
    align-items: center;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #353535;
}
.formControl::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em rgba(5, 16, 51, 0);
}
.formControl:checked::before {
    transform: scale(1);
}
.containt{
    margin-top: 17px;
    display: flex;
    align-items: center;
}
input[type="radio"] {
    margin:0px 3px 0px 0px !important;
    
}
.disabledText label{
    color: #949494;
}