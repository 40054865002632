.buttonSizeContainer {
    padding: 0;
}
.buttonsContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    row-gap: 6px;
    column-gap: 3px;
    flex-wrap: wrap;
}
.buttonSize {
    padding: 0px 3%;
    border: none;
    background-color: transparent;
    color: #434A48;
    cursor: pointer;
    transition-duration: 0.4s;
    height: 38px;
    font-family: Mulish;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    border: 1px solid #C7CECC;
    white-space: nowrap;
}
.buttonSize:hover:disabled {
    color: #434A48;
    background-color: #FFFF;
}
.buttonSize:disabled {
    cursor: auto;
    opacity: 0.4;
    background-color: #FFFF;
    background: 
         linear-gradient(to top right,
             rgba(0,0,0,0) 0%,
             rgba(0,0,0,0) calc(50% - 0.8px),
             rgba(255,0,0,1) 50%,
             rgba(0,0,0,0) calc(50% + 0.8px),
             rgba(0,0,0,0) 100%);
}
.buttonSize:hover {
    background-color: #C7CECC;
    color: #434A48;
}
.selected {
    background-color: #C7CECC;
    color: #434A48;
}
.colorsContainer {
    display: flex;
    flex-direction: row;
    gap: 10px;
}