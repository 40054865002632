.containerSuccess{
    display: flex;
    align-items: center;
    background-color: #FFF;
    padding: 22px;
    max-height: 60px;
    box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05);

}
.buttonCheck{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    min-width: 24px;
    height: 24px;
    border-radius: 50px;
    background: linear-gradient(225deg, #000000 0%, #D1D1D1 100%);
    margin-right: 16px;
}