/*apply when more than 744px*/
@media (min-width:744px) {
    .addFavoritesButton {
        border: none;
        cursor: pointer;
        background: none;
    }
}

/*apply when less than 744px*/
@media (max-width:744px) {
    .addFavoritesButton {
        border: none;
        cursor: pointer;
        background: none;
    }
    .heartImg{
        width: 14.75px;
        height: 12.72px;
    }
}