.inputsArea {
    display: flex;
    flex-direction: column;
}

.inputSections {
    display: flex;
    flex-direction: column;
    margin: 12px 24px 12px 24px;
}

.itemInput {
    background-color: #fff;
    box-shadow: 0px 0px 1px 0px #32324733;
    border: none;
    margin-top: 8px;
    height: 36px;
    left: 0px;
    top: 25px;
    border-radius: 0px;
    padding: 0px 12px;
}

.itemInputSelect {
    background-color: #fff;
    box-shadow: 0px 0px 1px 0px #32324733;
    border: none;
    margin-top: 8px;
    height: 36px;
    left: 0px;
    top: 25px;
    border-radius: 0px;
    padding: 0px 12px;
}

.typeOfSelection {
    font-family: "Mulish";
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    color: #949494;
}

.valueHeight {
    font-family: "Mulish";
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    /* color: #686868; */
    color: #425466;
}

.textSpan {
    font-family: "Raleway";
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    color: #686868;
}

.faqSize {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: transparent;
    color: #353535;
    cursor: pointer;
    padding: 12px 19px 12px 19px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    margin-top: 32px;
    font-family: "Raleway";
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
}

.helpMeasureArea {
    display: flex;
    justify-content: center;
    margin-bottom: 28px;
}

.helpMeasure {
    font-family: "Raleway";
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: center;
    color: #5F6867;
}
.itemInput:disabled, .itemInputSelect:disabled, .itemInput2:disabled{
    background-color: #F2F2F2;
}
.itemInput:focus, .itemInputSelect:focus, .itemInput2:focus{
    border: 1px solid #732130;
}