.container{
    display: flex;
    flex-direction: column;
    padding: 0px 22px;
}
.contianForm{
    margin-bottom: 28px;
    background-color: #F2F2F2;
    padding: 24px;
}
.inputGroup{
    margin-bottom: 16px;
}
.formControl{
    align-items: flex-start !important;
}
.styleInput {
    background: #FFFFFF;
    width: 100% !important;
    height: 36px;
    border-radius: 0px !important;
    border: none;
    box-shadow: 0px 1px 2px rgba(50, 50, 71, 0.08), 0px 0px 1px rgba(50, 50, 71, 0.2);
    padding: 0px 12px !important;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;
}

.styleInputError {
    border: 1px solid #DF585F;
    box-shadow: none;
}

.styleInput:focus-visible {
    outline: 1px solid #732130;
}
.inputStripe{
    padding-top: 10px;
}
.inlineInputs{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.inputGroupMiddle{
    margin-bottom: 16px;
    width: 46%;
}
.styleInputMiddle{
    background: #FFFFFF;
    height: 36px;
    border: none;
    box-shadow: 0px 1px 2px rgba(50, 50, 71, 0.08), 0px 0px 1px rgba(50, 50, 71, 0.2);
    padding: 0px 12px !important;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;
}
.containerCart{
    margin-top: 16px;
}
.collapsible {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #1E202C;
    cursor: pointer;
    padding: 0px 36px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    margin-top: 32px;
}
.collapsible span {
    font-family: "Raleway";
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
}
.container {
    overflow-y: hidden;
}
.display {
    display: block;
    width: 83%;
    margin-left: 8.5%;
    margin-top: 24px;
}
.hidden > * {
    animation:0.5s both diminishHeight;
}

.flexColumCenterContent{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 10px;
}
.checkContainer{
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.spanCheck{
    font-family: Mulish;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
}
@keyframes diminishHeight {
    from {
        height: 100px;
        opacity: 1;

        /*same style*/
        width: 83%;
        margin-left: 8.5%;
    }

    to {
        height: 0px;
        opacity: 0.5;

        /*same style*/
        width: 83%;
        margin-left: 8.5%;
    }
}
p{margin: 0;}
@media (max-width: 774px){
    .collapsible{
        background-color: #FFF;
        padding: 0px 20px;
    }
    .display {
        display: block;
        width: 85%;
        margin-left: 0px;
        margin-top: 24px;
    }
}
.containPrices{
    display: block;
    flex-direction: column;
    margin-bottom: 20px;
}
.titleConfirm{
    font-size: 17px;
    color: #5F4840;
}
.inlineContainer{
    display: flex;
    justify-content: space-between;
}