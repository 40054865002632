.container {
    display: flex;
    flex-direction: column;
    padding: 16px 22px;
}
.containerContent {
    display: flex;
    flex-direction: row;
    gap: 12px;
    padding: 16px 22px;
    margin-top: 40px;
    flex-wrap: wrap;
}
.containerProducts {
    display: flex;
}
.containerCode {
    display: flex;
    flex-direction: column;
    padding: 16px 22px;
    margin-bottom: 40px;
}
.collapsible {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: transparent;
    color: #1E202C;
    cursor: pointer;
    padding: 12px 19px 12px 24px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    margin-top: 32px;
}
.collapsible span {
    font-family: "Raleway";
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
}
.display{
    display: block;
}

.hidden{
    display: none;
}

@media (min-width: 768px) {
    .containerProducts {
        width: 300px;
    }
}

@media (max-width: 767px) {
    .containerContent {
        margin-top: 0px;
    }
}
