.spanTitle{
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #353535;
}
.containerPriceElement{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.priceDescription{
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #1E202C;
}
.numberContainer {
    display: flex;
    flex-direction: row;
    background-color: #FFFFFF;
    border: 1px solid #F2F2F2;
}
.numberButton {
    padding: 10px;
    max-width: 48px;
    max-height: 44px;
    cursor: pointer;
}
.numberInput {
    max-width: 43px;
    max-height: 44px;
    border-style: none;
    font-family: 'Raleway';
    font-size: 19px;
    text-align: center;
}
.numberInput:focus-visible {
    outline: none;
}