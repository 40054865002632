.container {
    background-color: #FFF;
}
.topLeftArea{
    display: flex;
    flex-direction: column;
}
.topRightArea {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}
.rowsSections{
    display: flex;
    justify-content: space-between;
}
.nav {
    grid-area: nav;
    position: sticky;
    top: 0;
    z-index: 10;
    box-shadow: 0px 0px 6px #e0e0e0;
}
.section1 {
    width: 380px;
    background-color: #FAFAFA;
    padding: 0px 12px;
}
.section2 {
    flex-grow: 1;
    height: fit-content;
    background-color: #FFFF;
    margin-bottom: 100px;
}
.topSection {
    display: flex;
    justify-content: space-between;
    padding: 0px 107px 0px 48px;  
    margin-top: 48px;
}
.modelsForEachPage {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.qtyModels {
    height: 40px;
    width: 84px;
    margin: 1px;
    padding: 10px;
    border: none;
    padding: 10px;
    box-shadow: 0px 2px 4px 0px #0510370F;
}

.filtersArea {
    display: flex;
    column-gap: 24px;
    margin-top: 16px;
}

.titleStyles {
    font-family: "Raleway";
    font-size: 42px;
    font-weight: 400;
    line-height: 56px;
    letter-spacing: 0em;
    text-align: left;
    color: #000;
}

.filterItem {
    min-height: 22px;
    padding: 2px 6px 2px 8px;
    background-color: #C7CECC;
    color: #434A48;
    font-family: Mulish;
    font-size: 11px;
    font-weight: 700;
    display: flex;
    gap: 6px;
    align-items: center;
    margin: 0px 5px;
}

.close {
    border: none;
    cursor: pointer;
    background: none;
}

.titlePaginationModels {
    font-family: "Raleway";
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: right;
    color:#5F4840;
    margin-right: 8px;
}

.paginationTop{
    display: flex;
    align-items: center;
}

ul.pagination {
    display: inline-block;
    padding: 0;
    margin: 0;
    height: 40px;
    box-shadow: 0px 2px 4px 0px #0510370F;
}

ul.pagination li {
    display: inline;
}

ul.pagination li a {
    color: black;
    float: left;
    padding: 0px 16px;
    text-decoration: none;
}

.paginationWrapper{
    display: flex;
    justify-content: flex-end;
    margin: 0 16px;
    align-items: center;
    gap: 8px;
}
.spanPage{
    font-family: Raleway;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: right;
    color:#5F4840;
}
.paginationContainer{
    display: flex;
    align-items: center;
    padding: 8px 0;
    background-color: white;
    box-shadow: 0px 2px 4px rgba(5, 16, 55, 0.06);
}
.arrows{
    width: 9px;
    height: 9px;
    padding: 7.5px 9.5px;
}
.pages{
    display: flex;
    align-items: center;
    font-family: Mulish;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
}
.inlineWrap{
    display: flex;
    flex-wrap: wrap;
    margin-top: 16px;
    margin-bottom: 16px;
}
.rotateCenter{
	-webkit-animation: rotate-center 6s linear infinite both;
	        animation: rotate-center 6s linear infinite both;
}
.itemFadeOut{
    -webkit-animation: fadeout .3s linear forwards;
    animation: fadeout .3s linear forwards;
}
.itemFadeIn {
    -webkit-animation: fadein .3s linear forwards;
    animation: fadein .3s linear forwards;
}

.allDressSearchContainer {
    display: flex;
    gap: 48px;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 10px;
    padding: 0px 48px;
    margin-bottom: 48px;
}

.toTop {
    position: fixed;
    width: 40px; 
    height: 40px;
    background: #5F4840;
    bottom: 20px; 
    right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 2147483649;
}

@media (min-width: 1451px) {
    .allDressSearchContainer {
        gap: 2vw;
    }
}


@media (max-width: 1450px) and (min-width: 1342px) {
    .allDressSearchContainer {
        gap: 2vw;
    }
}

@media (max-width: 1341px) and (min-width: 1255px) {
    .allDressSearchContainer {
        gap: 1.5vw;
    }
}

@media (max-width: 1254px) and (min-width: 1150px) {
    .allDressSearchContainer {
        gap: 2vw;
    }
}

@media (max-width: 1150px) and (min-width: 1024px) {
    .allDressSearchContainer {
        gap: 2vw;
    }
}

@-webkit-keyframes fadein {
    0% { opacity: 0; }
    100% { opacity: 1; }
}
    
@keyframes fadein {
    0% { opacity: 0; }
    100% { opacity: 1; }
}
    
@-webkit-keyframes fadeout {
    0% { opacity: 1; }
    100% { opacity: 0; }
}
    
@keyframes fadeout {
    0% { opacity: 1; }
    100% { opacity: 0; }
}


@-webkit-keyframes rotate-center {
    0% {
      -webkit-transform: rotate(0);
              transform: rotate(0);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
}
@keyframes rotate-center {
    0% {
      -webkit-transform: rotate(0);
              transform: rotate(0);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
}