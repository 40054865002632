.select{
    background: #FFFFFF;
    box-shadow: 0px 1px 2px rgba(50, 50, 71, 0.08), 0px 0px 1px rgba(50, 50, 71, 0.2);
    height: 36px;
    width: 100%;
    border: none;
    padding: 0px 17px;
}
.select:focus-visible {
    outline: none;
}
.select::placeholder{
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #949494;
}
.select:focus-visible{
    border: 1px solid #732130 !important;
}
.select:focus{
    border: 1px solid #732130 !important;
}
.label{
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    text-transform: uppercase;
    color: #686868;
    margin-bottom: 9px;
}