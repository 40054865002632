/*apply when more than 744px*/
.discountText{
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 14px;
    color: #FBCBBE;
}
@media (min-width: 744px) {
    .containerImage {
        position: relative;
        width: fit-content;
        cursor: pointer;
    }
    .containerCardDress{
        position: relative;
        max-width: 320px;
    }
    .heartBtn {
        position: absolute;
        top: 11.5px;
        right: 10.5px;
    }
    .dressInfoContainer {
        overflow: hidden;
        display: absolute;
        flex-flow: column;
        padding: 16px;
        margin-bottom: 20px;
        max-width: 100%;
    }
    .dressModelName {
        font-family: Raleway;
        font-size: 19px;
        font-weight: 700;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
        color: #fff;
        margin-bottom: 4px;
    }
    .dressModelPrice {
        font-family: Mulish;
        font-size: 13px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: left;
        color: #fff;
    }
}

.dressSearchImages {
    width: 100%;
    height: 46vw;
    max-height: 450px;
    object-fit: cover;
    border-radius: 24px;
}

@media (min-width: 1850px) {
    .dressSearchImages {
        height: 25.5vw;
        max-height: 480px;
    }
}

@media (max-width: 1849px) and (min-width: 1760px) {
    .dressSearchImages {
        height: 25vw;
        max-height: 470px;
    }
}

@media (max-width: 1759px) and (min-width: 1678px) {
    .dressSearchImages {
        height: 24.5vw;
        max-height: 460px;
    }
}

@media (max-width: 1677px) and (min-width: 1604px) {
    .dressSearchImages {
        height: 24vw;
        max-height: 460px;
        width: 255px;
    }
}

@media (max-width: 1603px) and (min-width: 1535px) {
    .dressSearchImages {
        height: 23vw;
        max-height: 450px;
        width: 240px;
    }
}

@media (max-width: 1534px) and (min-width: 1473px) {
    .dressSearchImages {
        height: 22.5vw;
        max-height: 450px;
        width: 224px;
    }
}

@media (max-width: 1472px) and (min-width: 1451px) {
    .dressSearchImages {
        height: 22.5vw;
        max-height: 324px;
        width: 214px;
    }
}

@media (max-width: 1450px) and (min-width: 1397px) {
    .dressSearchImages {
        height: 22vw;
        max-height: 324px;
        width: 206px;
    }
}

@media (max-width: 1396px) and (min-width: 1363px) {
    .dressSearchImages {
        height: 22vw;
        max-height: 324px;
        width: 200px;
    }
}

@media (max-width: 1362px) and (min-width: 1317px) {
    .dressSearchImages {
        height: 21.5vw;
        max-height: 324px;
        width: 194px;
    }
}

@media (max-width: 1316px) and (min-width: 1290px) {
    .dressSearchImages {
        height: 21.5vw;
        max-height: 324px;
        width: 185px;
    }
}

@media (max-width: 1289px) and (min-width: 1255px) {
    .dressSearchImages {
        height: 21vw;
        max-height: 324px;
        width: 178px;
    }
}

@media (max-width: 1254px) and (min-width: 1192px) {
    .dressSearchImages {
        height: 27vw;
        max-height: 430px;
        width: 221px;
    }
}

@media (max-width: 1191px) and (min-width: 1151px) {
    .dressSearchImages {
        height: 27vw;
        max-height: 430px;
        width: 207px;
    }
}

@media (max-width: 1150px) and (min-width: 1063px) {
    .dressSearchImages {
        height: 38vw;
        max-height: 430px;
        width: 280px;
    }
}

@media (max-width: 1062px) and (min-width: 1025px) {
    .dressSearchImages {
        height: 38vw;
        max-height: 430px;
        width: 260px;
    }
}

@media (max-width: 1024px) and (min-width: 989px) {
    .dressSearchImages {
        height: 44vw;
        max-height: 450px;
        width: 292px;
    }
}

@media (max-width: 988px) and (min-width: 957px) {
    .dressSearchImages {
        height: 44vw;
        max-height: 450px;
        width: 282px;
    }
}

@media (max-width: 956px) and (min-width: 929px) {
    .dressSearchImages {
        height: 44vw;
        max-height: 450px;
        width: 273px;
    }
}

@media (max-width: 928px) and (min-width: 913px) {
    .dressSearchImages {
        height: 44vw;
        max-height: 450px;
        width: 268px;
    }
}

@media (max-width: 912px) and (min-width: 899px) {
    .dressSearchImages {
        height: 44vw;
        max-height: 450px;
        width: 263px;
    }
}

@media (max-width: 898px) and (min-width: 872px) {
    .dressSearchImages {
        height: 43.5vw;
        max-height: 450px;
        width: 255px;
    }
}

@media (max-width: 871px) and (min-width: 850px) {
    .dressSearchImages {
        height: 43.5vw;
        max-height: 450px;
        width: 248px;
    }
}

@media (max-width: 849px) and (min-width: 818px) {
    .dressSearchImages {
        height: 43.5vw;
        max-height: 450px;
        width: 238px;
    }
}

@media (max-width: 817px) and (min-width: 787px) {
    .dressSearchImages {
        height: 43.5vw;
        max-height: 450px;
        width: 228px;
    }
}

@media (max-width: 786px) and (min-width: 698px) {
    .containerCardDress{
        max-width: 300px;
        position: relative;
    }
}

@media (max-width: 786px) and (min-width: 762px) {
    .dressSearchImages {
        height: 42.5vw;
        max-height: 450px;
        width: 220px;
    }
}

@media (max-width: 761px) and (min-width: 730px) {
    .dressSearchImages {
        height: 42.5vw;
        max-height: 450px;
        width: 210px;
    }
}

@media (max-width: 729px) and (min-width: 698px) {
    .dressSearchImages {
        height: 42.5vw;
        max-height: 450px;
        width: 200px;
    }
}

@media (max-width: 697px) and (min-width: 663px) {
    .dressSearchImages {
        height: 66vw;
        max-height: 460px;
        width: 290px;
    }
}

@media (max-width: 662px) and (min-width: 620px) {
    .dressSearchImages {
        height: 65.5vw;
        max-height: 460px;
        width: 270px;
    }
}

@media (max-width: 619px) and (min-width: 582px) {
    .dressSearchImages {
        height: 65vw;
        max-height: 460px;
        width: 250px;
    }
}

@media (max-width: 581px) and (min-width: 548px) {
    .dressSearchImages {
        height: 64.5vw;
        max-height: 460px;
        width: 235px;
    }
}

@media (max-width: 547px) and (min-width: 518px) {
    .dressSearchImages {
        height: 64vw;
        max-height: 460px;
        width: 220px;
    }
}

@media (max-width: 517px) and (min-width: 491px) {
    .dressSearchImages {
        height: 63.5vw;
        max-height: 460px;
        width: 205px;
    }
}

@media (max-width: 490px) and (min-width: 467px) {
    .dressSearchImages {
        height: 63vw;
        max-height: 480px;
        width: 195px;
    }
}

@media (max-width: 466px) and (min-width: 451px) {
    .dressSearchImages {
        height: 66vw;
        max-height: 480px;
        width: 200px;
    }
}

@media (max-width: 450px) and (min-width: 430px) {
    .dressSearchImages {
        height: 66vw;
        max-height: 480px;
        width: 190px;
    }
}

@media (max-width: 429px) and (min-width: 411px) {
    .dressSearchImages {
        height: 66vw;
        max-height: 480px;
        width: 180px;
    }
}

@media (max-width: 410px) and (min-width: 401px) {
    .dressSearchImages {
        height: 65vw;
        max-height: 480px;
        width: 175px;
    }
}

@media (max-width: 400px) and (min-width: 390px) {
    .dressSearchImages {
        height: 65vw;
        max-height: 480px;
        width: 170px;
    }
}

@media (max-width: 389px) and (min-width: 380px) {
    .dressSearchImages {
        height: 65vw;
        max-height: 480px;
        width: 165px;
    }
}

@media (max-width: 379px) and (min-width: 370px) {
    .dressSearchImages {
        height: 65vw;
        max-height: 480px;
        width: 160px;
    }
}

@media (max-width: 369px) and (min-width: 360px) {
    .dressSearchImages {
        height: 65vw;
        max-height: 480px;
        width: 155px;
    }
}

@media (max-width: 359px) and (min-width: 348px) {
    .dressSearchImages {
        height: 64vw;
        max-height: 480px;
        width: 150px;
    }
}

@media (max-width: 348px) and (min-width: 340px) {
    .dressSearchImages {
        height: 64vw;
        max-height: 480px;
        width: 145px;
    }
}

/*apply when less than 744px*/
@media (max-width: 744px){
    .containerImage {
        position: relative;
        width: fit-content;
        cursor: pointer;
    }
    .containerCardDress{
        /* max-width: 170px; */
        position: relative;
    }
    .heartBtn {
        position: absolute;
        top: 9.5px;
        right: 8.88px;
    }
    .dressInfoContainer {
        display: flex;
        flex-flow: column;
        padding: 12px 8px;
        max-width: 100%;
    }
    /* .dressSearchImages {
        width: 100%;
        height: 322px;
        object-fit: cover;
        border-radius: 24px;
    } */
    .dressModelName {
        font-family: Raleway;
        font-size: 14px;
        font-weight: 600;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: left;
        color: #fff;
        margin-bottom: 4px;
    }
    .dressModelPrice {
        font-family: Mulish;
        font-size: 12px;
        font-weight: 400;
        line-height: 15px;
        letter-spacing: 0em;
        text-align: left;
        color: #fff;
    }
}

@media (max-width: 450px) {
    /* .containerCardDress{
        position: relative;
        max-width: 156px;
        margin-bottom: 30px;
    }
    .dressSearchImages {
        width: 156px !important;
        height: 236px !important;
        object-fit: cover;
        border-radius: 24px;
    } */
}