.titleFilters {
    display: flex;
    justify-content: center;
    margin-top: 48px;
    font-family:"Raleway";
    font-size: 23px;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
    color:#87655B;
}
.scrollContaint{
    max-height: 100%;
    width: 100%;
    min-width: 346px;
    max-width: 346px;
    overflow-y: auto;
    background-color: #FAFAFA !important;
    transition: color .3s ease;
    color: rgba(0, 0, 0, 0);
    position: sticky;
    top: 75px;
}

.scrollContaint::-webkit-scrollbar {
  width: 5px;
  border-radius: 13px;
  background-clip: padding-box;
  border: 5px solid transparent;
}

.scrollContaint::-webkit-scrollbar-thumb {        
  box-shadow: inset 0 0 0 10px;
  border-radius: 13px;
}

.scrollContaint:hover {
    color: rgba(0, 0, 0, 0.3);
  }