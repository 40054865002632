.headerItem{
    background-color: #FAF6F5;
    box-shadow: 0px -1px 0px 0px #EDF2F7 inset;
    height: 52px;
    min-width: calc(100% / 7) !important;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #353535;
    text-transform: uppercase;
}
.itemRow{
    min-width: calc(100% / 7) !important;
    height: 52px;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #353535;
    box-shadow: 0px -1px 0px 0px #EDF2F7 inset;
}

@media (max-width: 774px){
    .headerItem{
        min-width: 100px !important;
    }
    .itemRow{
        min-width: 100px !important;
    }
}
.inlineContainer{
    border: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100% !important;
}

.pointColor{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #3F7FB5;
    margin-right: 12px;
}
.tooltip {
    position: relative;
}
.tooltip__item {
    position: absolute;
    min-width: 50px;
    padding: 10px;
    visibility: hidden;
    opacity: 0;
    background: #FFF;
    transition: all 0.25s cubic-bezier(0, 0, 0.2, 1);
    color: #484848;
    border: 1px solid #cecece;
    border-radius: 3px;
    font-weight: 500;
    box-shadow: 0 2px 1px #bcbcbc;
    z-index: 4;
}
.tooltip[data-direction="bottom"] .active {
    transform: translate3d(-50%, 0, 0);
    visibility: visible;
    opacity: 1;
    background-color: #FFF;
    z-index: 9;
    width: 100%;
}
.tooltip[data-direction="bottom"] .tooltip__item {
    left: 50%;
    transform: translate3d(-50%, -15px, 0);
}
.tooltip[data-direction="bottom"] .tooltip__item:after {
    left: 50%;
    transform: translate3d(-50%, 0, 0);
}
.tooltip__initiator {
    cursor: pointer;
    z-index: 5;
}
.tooltip[data-direction="bottom"] .tooltip__item {
    top: calc(100% + .01em);
}
.paginationWrapper{
    display: flex;
    justify-content: flex-end;
    margin: 0 16px;
    padding: 16px 0;
    align-items: center;
    gap: 8px;
}
.spanPage{
    font-family: Raleway;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: right;
    color:#5F4840;
}
.qtyModels{
    box-shadow: 0px 2px 4px rgba(5, 16, 55, 0.06);
    border: none;
}
.paginationContainer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 0;
    min-width: 108px;
    background-color: white;
    box-shadow: 0px 2px 4px rgba(5, 16, 55, 0.06);
}
.pages{
    display: flex;
    align-items: center;
    font-family: Mulish;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
}
.containerDescription{
    height: 60px;
    display: flex;
    align-items: center;
    padding: 24px;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 13px;
    color: #353535;
    border-top: 1px solid #EDF2F9;
}
.tableResponsive{
    max-width: 100%;
    overflow-x: auto;
    max-height: auto;
    margin-top: 16px;
}
.arrows {
    cursor: pointer;
}