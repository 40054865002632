.container {
    width: 100%;
    padding: 4% 0;
    /* background-color: #F7EFED; */
    background-color: #C7CECC;
}

.containerGrid {
    display: flex;
    justify-content: center;
    gap: 64px;
}

.socialNetworksContainer{
    display: flex;
    justify-content: center;
    margin-top: 40.36px;
}

.containerGrid section {
    display: flex;
    flex-flow: column;
}

.process{
    width: 106px;
}

.help{
    width: 69px;
}

.titleFooter {
    display: flex;
    justify-content: flex-start;
    font-family: "Raleway";
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    /* color: #5F4840; */
    color: #434A48;
}

.titleSocialNetworks {
    display: flex;
    justify-content: center;
    font-family: "Raleway";
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    /* color: #5F4840; */
    color: #434A48;
}

.socialNetworksButton{
    border: none;
    cursor: pointer;
    background: none;
    padding: 12px 20px 12px 12px;
}

.footerContent {
    font-family: "Raleway";
    font-size: 12px;
    font-weight: 400;
    line-height: 14.09px;
    letter-spacing: 0em;
    text-align: left;
    /* color:#87655B; */
    color: #434A48;
    margin-top: 8.435%;
    text-decoration: none;
}

.containerSorella{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 4%;
}

.sorella {
    color: #5F4840;
    font-family: Raleway;
    font-size: 40px;
    font-weight: 800;
    letter-spacing: 12px;
}