.container {
    display: grid;
    grid-template-columns: 1fr 1fr;

}

.leftArea {
    width: 124%;
    z-index: 1;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.subLeftArea {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.fillBlackPointLeftThenLine {
    padding-bottom: 2%;
    width: 60%;
    padding-left: 3%;
}

.textAndLine {
    display: flex;
    align-items: baseline;
    width: 100%;
}

.title {
    /* font-family: Fraunces;
    font-size: 48px;
    font-weight: 400;
    line-height: 59px;
    letter-spacing: 0em;
    text-align: left; */
    font-family: Poppins;
    font-size: 48px;
    font-weight: 400;
    line-height: 72px;
    letter-spacing: 0em;
    text-align: left;
    color: #732130;
}


.aboveTitle {
    font-family: Mulish;
    font-size: 28px;
    font-weight: 500;
    line-height: 35px;
    letter-spacing: 0em;
    text-align: justified;

    margin-top: 24px;
    margin-bottom: 56px;
}

.textArea {
    display: flex;
    flex-direction: column;
    flex-basis: 338px;
}

.rightArea {
    width: 100%;
    cursor: pointer;
}