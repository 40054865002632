.container {
    display: block;
}

.nav {
    grid-area: nav;
    width: 100%;
}

.containerMobile {
    padding: 24px 16px;
}

.filterSvg {
    padding: 4.88px;
}

.spanFilter {
    font-family: Raleway;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: right;
    color: #5F4840;
}

.btnFilterWrapper {
    display: flex;
    gap: 8px;
    align-items: center;
}

.inlineContainerSpaced {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 400px;
    margin-top: 22px;
    margin-bottom: 24px;
}

.inlineContainer {
    display: flex;
    align-items: center;
    gap: 5px;
    min-width: 400px;
}

.inlineDatesContainer {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    min-width: 460px;
}

.tableResponsive {
    max-width: 100%;
    overflow-x: auto;
    max-height: auto;
    margin-top: 16px;
}

.searchSelect {
    height: 40px;
    width: 140px;
    margin: 1px;
    padding: 10px;
    border: none;
    padding: 10px;
    box-shadow: 0px 2px 4px 0px #0510370F;
}

.searchInput {
    min-width: 400px;
}

.dateInput {
    font-family: Raleway;
    box-shadow: none !important;
    outline: none !important;
    background-color: transparent !important;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #000;
    min-width: 100px;
    max-width: 100px;
}

.buttonFilter {
    max-width: 100px;
    min-width: 100px;
}

.link {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
}