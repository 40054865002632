.registerContainer{
    display: flex;
    flex-direction: column;
}

.accountSingIn{
    margin-top: 6.235%;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    text-align: center;

    color: #5F6867;
    text-decoration: none;
    
    border: none;
    cursor: pointer;
    background: none;
}

.spanTermsAndConditions{
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 14px;
    /* identical to box height */
    /* display: flex;
    justify-content: end; */
    /* General/Neutral/600 */
    color: #949494;
    text-decoration: none;
}

.linkTermsAndConditions{
    font-family: 'Mulish';
    font-weight: 400;
    font-size: 11px;
    line-height: 14px;

    color: #949494;
}

.errorMessage {
    font-family: Mulish;
    font-size: 11px;
    font-weight: 400;
    color: #DF585F;
}

.success{
    border-color: #3DA558 !important;
}

.centerDivider {
    display:flex;
    justify-content:center;
    align-items: center;
    color:grey;
    margin: 8px 0px;
    font-family: Raleway;
}

.centerDivider::before,
.centerDivider::after {
    content: "";
    display: block;
    background: grey;
    width: 45%;
    height: 1px;
}

.centerDivider::before {
    margin: 0 10px 0 0;
}

.centerDivider::after {
    margin: 0 0 0 10px;
}